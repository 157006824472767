import { AppDispatch } from '@app/store';
import { CompanyStatus, ICompany } from '@app/types';
import ConfirmationDialog from '@common/components/dialog/confirmationDialog';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface FreezeCompanyModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  company: ICompany;
  isFrozen: boolean;
}

const FreezeCompanyModal: React.FC<FreezeCompanyModalProps> = ({
  open,
  onClose,
  onConfirm,
  company,
  isFrozen,
}) => {
  const { t } = useTranslation();

  const title = isFrozen ? t('accountManager.modals.unfreezeCompany') : t('accountManager.modals.freezeCompany');
  const content = `Do you really want to ${isFrozen ? 'unfreeze' : 'freeze'} the company?`;
  const subtitleItems = [{ key: t('accountManager.modals.companyName'), value: company.name }];

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={title}
      subtitleItems={subtitleItems}
      content={content}
      onConfirm={onConfirm}
    />
  );
};

export default FreezeCompanyModal;
