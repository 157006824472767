import { TableContainer } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CommentModal, DrawerGeneral, LoadingSkeleton } from '@common/components';
import { useOpenDrawer } from '@app/hooks';
import { DataGridPremium, GridRowParams } from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import { IHedge } from '@app/types';
import { ScrapexColorEnum } from '@common/colors';
// import { DetailPanelContent } from './collapsibleRow';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/store';
import { DetailPanelContent } from 'src/hedgeAdmin/hedging/shared/table/collapsibleRow';

import {
  CollapseIcon,
  ExpandIcon,
} from 'src/hedgeAdmin/hedging/shared/table/collapsibleRow/components/Icons';
import { getHedgeHistoryThunk } from 'src/account/reducer/hedging/thunk';
import { generateHedgeHistoryColumns } from './data/hedgeHistoryColumns';
import { generateHedgeHistoryRows } from './data/hedgeHistoryRowsData';
import { HedgeRequestDrawer } from 'src/account/contract/components/shipments/hedging/components/hedgeRequestDrawer';
import { TableHeader } from 'src/account/common';

export const HedgeHistoryTable = () => {
  const { t } = useTranslation();

  const hedges = useSelector((state: RootState) => state.account.hedging.hedgeHistory);
  const isLoading = useSelector((state: RootState) => state.account.hedging.isLoading);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [selectedHedge, setSelectededHedge] = useState<IHedge | null>(null);

  const hedgeHistoryHeaderConfig = {
    title: `${t('account.contract.hedgeHistory')} (${hedges?.length})`,
  };

  const handleCommentIconClick = (event: React.MouseEvent, hedge: IHedge) => {
    event.stopPropagation();
    setSelectededHedge(hedge);
    setIsCommentModalOpen(true);
  };

  const handleCommentModalClose = () => {
    setIsCommentModalOpen(false);
    setSelectededHedge(null);
  };

  const { columns } = generateHedgeHistoryColumns({
    onHandleCommentIconClick: handleCommentIconClick,
    t
  });
  const { rows } = generateHedgeHistoryRows(hedges);

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getHedgeHistoryThunk());
  }, []);
  const { isOpen, open, close } = useOpenDrawer();

  const handleRowClick = (params: GridRowParams) => {
    setSelectededHedge(params.row);
    open();
  };

  //   const getDetailPanelContent = useCallback<
  //     NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  //   >(({ row }) => <DetailPanelContent row={row} isItHistory />, []);

  return (
    <>
      <TableContainer sx={{ background: ScrapexColorEnum.WHITE_COLOR, maxHeight: '100vh' }}>
        <div style={{ width: '1540px', overflowX: 'auto', height: '85vh' }}>
          {isLoading && <LoadingSkeleton />}
          {hedges && !isLoading && (
            <DataGridPremium
              sx={{
                border: 'none',
                '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },

                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                },
                '& .MuiDataGrid-columnHeaders': {
                  color: '#8592A8',
                  background: '#F9FAFB',
                  border: 'none',
                },
              }}
              columnHeaderHeight={40}
              rows={rows}
              columns={columns}
              disableColumnFilter
              slots={{ toolbar: TableHeader }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  ...hedgeHistoryHeaderConfig,
                },
              }}
              hideFooter
              onRowClick={handleRowClick}
              initialState={{
                pinnedColumns: {
                  left: ['hedge_status', 'hedge_type', 'contract_number', 'hedge_number', 'role'],
                },
                sorting: {
                  sortModel: [{ field: 'filled_time', sort: 'desc' }],
                },
              }}
              sortingOrder={['desc', 'asc']}
              //   getDetailPanelContent={getDetailPanelContent}
              //   getDetailPanelHeight={({ row }) => 'auto'}
              //   slots={{
              //     detailPanelExpandIcon: ExpandIcon,
              //     detailPanelCollapseIcon: CollapseIcon,
              //   }}
              disableColumnMenu
            />
          )}
        </div>
        <DrawerGeneral onClose={close} open={isOpen}>
          {isOpen && selectedHedge && (
            <HedgeRequestDrawer hedgeId={selectedHedge.id} onClose={close} />
          )}
        </DrawerGeneral>
        {isCommentModalOpen && (
          <CommentModal
            open={isCommentModalOpen}
            hedge={selectedHedge as IHedge}
            onClose={handleCommentModalClose}
          />
        )}
      </TableContainer>
    </>
  );
};
