import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { HedgingStatus, IHedgingDetails } from '@app/types';
import classes from './styles.module.scss';

export const hedgeStatusMap: { [key in HedgingStatus]: string } = {
  [HedgingStatus.None]: 'noStatus',//'No Status',
  //acm
  [HedgingStatus.Quotation]: 'quotation', //'Quotation',
  [HedgingStatus.Waiting]: 'request', //'Request',
  [HedgingStatus.Filled]: 'filled', //'Filled',
  [HedgingStatus.Confirmed]: 'confirmed', //'Confirmed',
  [HedgingStatus.AdminRejected]: 'admCanceled', //'Adm. Canceled',
  [HedgingStatus.AdminRejectedTimeout]: 'timeOut', //'Time Out',
  [HedgingStatus.Timeout]: 'timeOut', //'Time Out',
  [HedgingStatus.AdminTimeout]: 'timeOut', //'Time Out',
  [HedgingStatus.AdminTimeoutConfirmed]: 'timeOut', //'Time Out',
  [HedgingStatus.UserTimeout]: 'timeOut', //'Time Out',
  [HedgingStatus.UserRejectedQuotation]: 'canceling', //'Canceling',
  [HedgingStatus.AdminConfirmedRejectedQuotation]: 'canceled', //'Canceled',
  //tp
  [HedgingStatus.WaitingTP]: 'request', //'Request',
  [HedgingStatus.AtWork]: 'atWork', //'At work',
  [HedgingStatus.FilledTP]: 'filled', //'Filled',
  [HedgingStatus.UserRejectedRequest]: 'canceling', //'Canceling',
  [HedgingStatus.UserRejectedOrder]: 'canceling', //'Canceling',
  [HedgingStatus.AdminRejectedOrderTP]: 'admCanceled', //'Adm. Canceled',
  [HedgingStatus.AdminRejectedTP]: 'admCanceled', //'Adm. Canceled',
  [HedgingStatus.AdminConfirmedRejectRequestTP]: 'cancelRequest', //'Cancel Request',
  [HedgingStatus.AdminConfirmedRejectOrderTP]: 'cancelOrder', //'Cancel Order',
  //olme
  [HedgingStatus.WaitingOLME]: 'request', //'Request',
  [HedgingStatus.AtWorkOLME]: 'atWork', //'At work',
  [HedgingStatus.FilledOLME]: 'filled', //'Filled',
  [HedgingStatus.UserRejectedRequestOLME]: 'canceling', //'Canceling',
  [HedgingStatus.UserRejectedOrderOLME]: 'canceling', //'Canceling',
  [HedgingStatus.AdminRejectedOLME]: 'admCanceled', //'Adm. Canceled',
  [HedgingStatus.AdminRejectedRequestOLME]: 'admCanceled', //'Adm. Canceled',
  [HedgingStatus.AdminConfirmedRejectRequestOLME]: 'cancelRequest', //'Cancel Request',
  [HedgingStatus.AdminConfirmedRejectOrderOLME]: 'cancelOrder', //'Cancel Order',
};

export const HedgingStatusIndicatorMobile = ({ hedge }: { hedge?: IHedgingDetails | null }) => {
  if (!hedge) {
    return <Box className={`${classes[HedgingStatus.None]} ${classes.statusMobile}`}>No hedge</Box>;
  }

  const { t } = useTranslation();
  const normalizedStatus = hedgeStatusMap[hedge.hedge_status as HedgingStatus];
  const myStatus = t(`account.contract.${normalizedStatus}}`)

  return (
    <Box className={`${classes[hedge.hedge_status]} ${classes.statusMobile}`}>
      {`${myStatus} · ${hedge.hedge_type.toUpperCase()}`}
    </Box>
  );
};

export const HedgingStatusIndicator = ({ status }: { status: HedgingStatus }) => {
  const { t } = useTranslation();
  const normalizedStatus = hedgeStatusMap[status as HedgingStatus]
  
  return (
    <Box className={`${classes[status]} ${classes.status}`}>
      {t(`account.contract.${normalizedStatus}`)}
    </Box>
  );
};
