import hero from './assets/hero.png';
import classes from './styles.module.scss';
import { useTranslation } from 'react-i18next';


export const Hero = () => {
  const { t } = useTranslation();
  
  return (
    <section className={`${classes.hero}`}>
      <main className={`${classes.hero__content} ${classes.content}`} id="scrapex">
        <article className={`${classes.hero__text} ${classes.text}`}>
          <h1>
            {t('landing.hero.scrap')}
            <span>{t('landing.hero.ex')}</span>
          </h1>
          <h3>{t('landing.hero.head')}</h3>
          <p>
          {t('landing.hero.description')}
          </p>
          <a href="#contact">
            <button>
              {t('landing.hero.button')}
            </button>
          </a>
        </article>
        <img src={hero} width="450" />
      </main>
    </section>
  );
};
