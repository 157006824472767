import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Box } from '@mui/material';
import { ShipmentStatus } from '@app/types';
import classes from './styles.module.scss';

export const getShipmentStatusLabel = (t: TFunction, key: ShipmentStatus): string => {
  const shipmentStatusMap: { [key in ShipmentStatus]: string } = {
    none: t('account.contract.noStatus'),
    in_delivery: t('account.contract.inDelivery'),
    delivered: t('account.contract.delivered'),
    loading: t('account.contract.loading'),
    completed: t('account.contract.completed'),
  };

  return shipmentStatusMap[key];
};

export const ShipmentStatusIndicator = ({ status }: { status: ShipmentStatus }) => {
  const { t } = useTranslation();

  return (
    <Box className={`${classes[status]} ${classes.status}`}>
      {getShipmentStatusLabel(t, status)}
    </Box>
  );
};
