import { IContractUser } from '@app/types';
import { ContractRole, ScrapexGrayText } from '@common/components';
import { Box, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { calculateEstimatedShipmentWeight } from 'src/accountManager/contract/utlis/calculateEstimatedShipmentWeight';
import { useTranslation } from 'react-i18next';

//TODO: move it to common
interface IContractRowData {
  value: string | ReactNode;
  sx?: SxProps;
}

export const generateContractRowData = (contract: IContractUser | null): IContractRowData[] => {
  const { t } = useTranslation();

  const estimatedShipmentWeight = calculateEstimatedShipmentWeight(
    contract?.product_weight,
    contract?.total_shipments,
  );
  const availableShipmentsForHedge =
    (contract?.total_shipments || 0) -
    (contract?.shipments_in_orders || 0) -
    (contract?.hedged_shipments || 0) -
    (contract?.request_shipments || 0);
  return [
    {
      value: <ContractRole role={contract?.customer_role} />,
    },
    {
      value: (
        <Box>
          <h6>{contract?.product}</h6>
          <ScrapexGrayText>{contract?.metal}</ScrapexGrayText>
        </Box>
      ),
    },
    {
      value: contract?.formula,
    },
    {
      value: contract?.product_weight,
    },
    {
      value: (
        <Box>
          <Typography>{contract?.total_shipments} {t('account.contract.shipments')}</Typography>
          <ScrapexGrayText>{estimatedShipmentWeight} = 1 {t('account.contract.shipment')}</ScrapexGrayText>
        </Box>
      ),
    },
    {
      value: (
        <Box>
          <Typography>{contract?.hedged_shipments}</Typography>
          <ScrapexGrayText>
            {contract?.shipments_in_orders} {t('account.contract.inOrders')}, {contract?.request_shipments} {t('account.contract.inRequest')}
          </ScrapexGrayText>
        </Box>
      ),
    },
    {
      value: `${availableShipmentsForHedge} ${t('account.contract.shipments')}`,
    },

    {
      value: (
        <Box>
          <Typography>{contract?.completed_shipments}</Typography>
          <ScrapexGrayText>{contract?.shipments_delivery_in_progress} {t('account.contract.inProgress')}</ScrapexGrayText>
        </Box>
      ),
    },
  ];
};
