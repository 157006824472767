import { Box, Button, ButtonGroup, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { AddOutlined, OpenInNewOutlined, DownloadingOutlined } from '@mui/icons-material';
import { ScrapexSidebarMediumTextH2 } from '@common/components';
import { useGetShipmentFiles } from '@app/hooks/useGetShipmentFiles';
import { FileModal } from './fileModal';
import { PhotoModal } from './photoModal';

interface FileSelectorProps {
  fileIdsProp?: string[];
  dealId?: string;
  folder: string;
  title: string;
  type: string;
  onHandleUpdateShipment: (pdfIds: string[]) => void;
}

export const FileSelector: React.FC<FileSelectorProps> = ({
  fileIdsProp = [],
  dealId = '',
  folder,
  onHandleUpdateShipment,
  title,
  type,
}) => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [fileIds, setFileIds] = useState<string[]>(fileIdsProp || []);
  const { link } = useGetShipmentFiles(dealId, folder);

  return (
    <>
      {fileIds.length > 0 ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: 1 }}>
          <ScrapexSidebarMediumTextH2>{fileIds.length}</ScrapexSidebarMediumTextH2>
          <ButtonGroup>
            <IconButton onClick={() => setModalOpen(true)}>
              <OpenInNewOutlined fontSize="small" />
            </IconButton>
            <IconButton href={link}>
              <DownloadingOutlined fontSize="small" />
            </IconButton>
          </ButtonGroup>
        </Box>
      ) : (
        <>
          <Button
            variant="outlined"
            color="warning"
            onClick={() => setModalOpen(true)}
            startIcon={<AddOutlined />}
          >
            {t('accountManager.shipment.add')}
          </Button>
        </>
      )}

      {type === 'files' && (
        <FileModal
          onFilesSelect={(filesIds: string[]) => {
            onHandleUpdateShipment(filesIds);
            setFileIds(filesIds);
          }}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          dealId={dealId}
          folder={folder}
          title={title}
          fileIdsProp={fileIdsProp}
        />
      )}

      {type === 'photo' && (
        <PhotoModal
          onFilesSelect={(filesIds: string[]) => {
            onHandleUpdateShipment(filesIds);
            setFileIds(filesIds);
          }}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          dealId={dealId}
          folder={folder}
          title={title}
          fileIdsProp={fileIdsProp}
        />
      )}
    </>
  );
};
