import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import { AddOutlined, DownloadOutlined, DownloadingOutlined } from '@mui/icons-material';
import { useUploadShipmentFiles } from '@app/hooks';
import {
  DialogTitleModal,
  ImageSquare,
  PhotoSlider,
  ScrapexGrayTextRegular,
} from '@common/components';
import { getFileUrl } from '@app/utils';

interface FileModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  dealId?: string;
  folder: string;
  onFilesSelect: (filesIds: string[]) => void;
  fileIdsProp: string[];
}

export const PhotoModal: React.FC<FileModalProps> = ({
  open,
  onClose,
  title,
  dealId = '',
  folder,
  onFilesSelect,
  fileIdsProp,
}) => {
  const { t } = useTranslation();

  const { upload } = useUploadShipmentFiles();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileIds, setFileIds] = useState<string[]>(fileIdsProp || []);
  const [dragging, setDragging] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const handleDownloadFile = async () => {
    const link = await getFileUrl(dealId, folder, 'admin');
    window.open(link, '_self');
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);

    const files = event.dataTransfer.files;
    handleFilesUpload(files);
  };

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    handleFilesUpload(files);
  };

  const handleFilesUpload = async (files: FileList | null) => {
    setLoading(true);
    if (files && files.length > 0) {
      try {
        const uploadedIds = await Promise.all(
          Array.from(files).map((file) => upload(file, dealId, folder)),
        );

        setFileIds((prevFileIds) => [...prevFileIds, ...uploadedIds]);
        onFilesSelect([...fileIds, ...uploadedIds]);
      } catch (err) {
        console.error('Upload failed:', err);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRemoveFile = (item: string) => {
    const updatedFileIds = fileIds.filter((fileId) => fileId !== item);

    setFileIds(updatedFileIds);
    onFilesSelect(updatedFileIds);
  };

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index);

    setIsSliderOpen(true);
  };

  const handleSliderClose = () => {
    setIsSliderOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: 800,
          width: 800,
          height: 539,
        },
      }}
    >
      <DialogTitleModal title={title} onClose={onClose} />

      {fileIds && fileIds.length > 0 ? (
        <>
          <DialogContent
            sx={{
              px: '24px',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '7px',
              border: dragging ? '2px dashed #000' : '2px dashed transparent',
            }}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {fileIds.map((item, index) => (
              <ImageSquare
                key={item}
                fileId={item}
                dealId={dealId}
                hasDownloadFile
                onClearClick={() => handleRemoveFile(item)}
                onImageClick={() => handleImageClick(index)}
                sx={{
                  justifyContent: 'space-between',
                  borderRadius: '4px',
                }}
              />
            ))}
          </DialogContent>
          <DialogActions
            sx={{ p: '24px', gap: 1, borderTop: '1px solid var(--light-white-color)' }}
          >
            <Button
              onClick={() => fileInputRef?.current?.click()}
              variant="outlined"
              color="warning"
              startIcon={<AddOutlined />}
            >
            {t('accountManager.shipment.addNew')}
            </Button>
            <Button
              variant="outlined"
              startIcon={<DownloadingOutlined />}
              onClick={handleDownloadFile}
            >
              {t('accountManager.shipment.downloadAll')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          {loading ? (
            <DialogContent
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <ScrapexGrayTextRegular>{t('accountManager.shipment.photosUploading')}</ScrapexGrayTextRegular>
              <CircularProgress />
            </DialogContent>
          ) : (
            <DialogContent
              sx={{
                textAlign: 'center',
                border: dragging ? '2px dashed #000' : '2px dashed transparent',
              }}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <Typography variant="subtitle1" color="textSecondary" mt={18}>
                {t('accountManager.shipment.noPhotos')}
              </Typography>
              <Button
                variant="outlined"
                onClick={() => fileInputRef?.current?.click()}
                sx={{ mt: 2 }}
                startIcon={<DownloadOutlined />}
              >
                {t('accountManager.shipment.upload')}
              </Button>
            </DialogContent>
          )}
        </>
      )}

      {isSliderOpen && (
        <PhotoSlider
          isOpen={isSliderOpen}
          onClose={handleSliderClose}
          images={fileIds}
          dealId={dealId}
          currentImageIndex={currentImageIndex}
        />
      )}
      <input
        ref={fileInputRef}
        hidden
        multiple
        type="file"
        id="file"
        name="file"
        accept="image/*"
        onChange={handleFileInputChange}
      />
    </Dialog>
  );
};
