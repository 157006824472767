import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import ModalSubtitle from '@common/components/dialog/dialogSubtitle';
import { ArrowForward } from '@mui/icons-material';
import { useState } from 'react';

interface EditOrderModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (message: string) => void;
}

export const EditOrderModal: React.FC<EditOrderModalProps> = ({ open, onClose, onConfirm }) => {
  const { t } = useTranslation();
  const [rejectMessage, setRejectMessage] = useState<string>('');

  const subtitleItems = [
    { key: 'You are going to edit the order, please select the necessary option for editing:' },
  ];

  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRejectMessage(event.target.value);
  };

  const handleConfirm = () => {
    onConfirm(rejectMessage);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: 755,
          width: 755,
          height: 360,
        },
      }}
    >
      <DialogTitle sx={{ paddingBottom: '8px', paddingTop: '24px', fontWeight: 700 }}>
      {t('hedge.editOrder')}
      </DialogTitle>
      <ModalSubtitle subtitleItems={subtitleItems} />
      <DialogContent>
        <RadioGroup defaultValue="cancelOrder">
          <FormControlLabel value="cancelOrder" control={<Radio />} label={t('hedge.CANCEL_ORDER')} />
        </RadioGroup>
        <InputLabel sx={{ margin: '14px 0 4px 0', fontSize: '14px', fontWeight: 500 }}>
        {t('hedge.adminComment')}
        </InputLabel>
        <TextField
          autoFocus
          multiline
          rows={2}
          maxRows={4}
          placeholder={t('hedge.enterYourCommentMin1')}
          type="text"
          fullWidth
          onChange={handleChangeMessage}
        />
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button onClick={onClose} variant="outlined" color="error">
        {t('hedge.close')}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={!rejectMessage}
          endIcon={<ArrowForward />}
        >
          {t('hedge.continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
