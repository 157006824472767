import { useTranslation } from 'react-i18next';
import { Box, Typography, Checkbox } from '@mui/material';
import classes from './styles.module.scss';
import { IShipmentUser, HedgingStatus, IHedge } from '@app/types';
import { HedgingStatusIndicatorMobile } from '@common/components';
import { renderDateOrNone } from '@app/utils';
import { useGetHedgeDetails } from '../../hooks/useGetHedgeDetails';
import { TFunction } from 'i18next';

interface ShipmentCardProps {
  shipment: IShipmentUser;
  ordinalNumber: number;
  selectable?: boolean;
  selected?: boolean;
  onSelect?: () => void;
  onHedgeClick?: () => void;
}

const isTPNotFilled = (status?: string) => {
  return (
    status === HedgingStatus.AtWork ||
    status === HedgingStatus.WaitingTP ||
    status === HedgingStatus.UserRejectedRequest ||
    status === HedgingStatus.UserRejectedOrder ||
    status === HedgingStatus.AdminRejectedOrderTP ||
    status === HedgingStatus.AdminRejectedTP ||
    status === HedgingStatus.AdminConfirmedRejectRequestTP ||
    status === HedgingStatus.AdminConfirmedRejectOrderTP
  );
};

const isOLMENotFilled = (status?: string) => {
  return (
    status === HedgingStatus.AtWorkOLME ||
    status === HedgingStatus.WaitingOLME ||
    status === HedgingStatus.UserRejectedRequestOLME ||
    status === HedgingStatus.UserRejectedOrderOLME ||
    status === HedgingStatus.AdminRejectedOLME ||
    status === HedgingStatus.AdminRejectedRequestOLME ||
    status === HedgingStatus.AdminConfirmedRejectRequestOLME ||
    status === HedgingStatus.AdminConfirmedRejectOrderOLME
  );
};

const getLmeLabel = (status?: string) => {
  if (isTPNotFilled(status)) {
    return 'TP Exchange level USD/mt:';
  }
  return 'Fixed LME, USD/mt:';
};

const getLmeValue = (shipment: IShipmentUser, hedgeDetails: IHedge | null) => {
  const status = shipment.company_hedge?.hedge_status;

  if (isTPNotFilled(status)) {
    return hedgeDetails?.tp_exchange_level || '-';
  }
  return shipment.fixed_exchange || '-';
};

const getPriceLabel = (status?: string) => {
  if (isTPNotFilled(status)) {
    return 'Estimated unit price, USD/mt:';
  }
  if (isOLMENotFilled(status)) {
    return 'Date:';
  }
  return 'Final Unit Price, USD/mt:';
};

// TODO: add to this t
const getPriceValue = (t: TFunction,  shipment: IShipmentUser, hedgeDetails: IHedge | null) => { 
  const status = shipment.company_hedge?.hedge_status;

  if (isTPNotFilled(status)) {
    return hedgeDetails?.tp_estimated_product_price || '-';
  }
  if (isOLMENotFilled(status)) {
    return renderDateOrNone(t, hedgeDetails?.olme_date || '');
  }
  return shipment.final_product_price || '-';
};

export const ShipmentCard = ({
  shipment,
  ordinalNumber,
  selectable,
  selected,
  onSelect,
  onHedgeClick,
}: ShipmentCardProps) => {
  const { hedgeDetails, loading } = useGetHedgeDetails(shipment.company_hedge?.id);
  const status = shipment.company_hedge?.hedge_status;

  const { t } = useTranslation();
  
  return (
    <Box
      className={`${classes.shipmentItem} ${selectable ? classes.selectable : ''}`}
      onClick={onSelect || (onHedgeClick && shipment.company_hedge ? onHedgeClick : undefined)}
    >
      <Box className={classes.shipmentHeader}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {selectable && (
            <Checkbox
              checked={selected}
              onClick={(e) => e.stopPropagation()}
              onChange={onSelect}
              sx={{ padding: 0 }}
            />
          )}
          <Box>
            <Typography variant="subtitle2">Ordinal No.: {ordinalNumber}</Typography>
            <Typography variant="body2" color="var(--fill-text-color)">
              {t('account.contract.shipment_')} {shipment.shipment_number || 'None'}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="caption" color="var(--fill-text-color)" textAlign="right">
          {t('account.contract.hedgeStatus')}
          </Typography>

          <HedgingStatusIndicatorMobile hedge={shipment.company_hedge} />
        </Box>
      </Box>

      <Box className={classes.shipmentDetails}>
        <Box className={classes.detailRow}>
          <Typography variant="body2">
            {shipment.final_weight ? 'Final Weight: ' : 'Estimated Weight: '}
          </Typography>
          <Typography variant="body2">
            {shipment.final_weight
              ? `${shipment.final_weight} mt`
              : `${shipment.estimated_weight} mt`}
          </Typography>
        </Box>
        <Box className={classes.detailRow}>
          <Typography variant="body2">ETA:</Typography>
          <Typography variant="body2">{renderDateOrNone(t, shipment.eta)}</Typography>
        </Box>
        {isOLMENotFilled(status) ? (
          <Box className={classes.detailRow}>
            <Typography variant="body2">Date:</Typography>
            <Typography variant="body2">
              {loading ? '...' : renderDateOrNone(t, hedgeDetails?.olme_date || '')}
            </Typography>
          </Box>
        ) : (
          <>
            <Box className={classes.detailRow}>
              <Typography variant="body2">
                {getLmeLabel(shipment.company_hedge?.hedge_status)}
              </Typography>
              <Typography variant="body2">
                {loading ? '...' : getLmeValue(shipment, hedgeDetails)}
              </Typography>
            </Box>
            <Box className={classes.detailRow}>
              <Typography variant="body2">
                {getPriceLabel(shipment.company_hedge?.hedge_status)}
              </Typography>
              <Typography variant="body2">
                {loading ? '...' : getPriceValue(t, shipment, hedgeDetails)}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
