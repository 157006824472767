import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '@common/components/dialog/confirmationDialog';

interface ResetPasswordModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  companyName: string;
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  open,
  onClose,
  onConfirm,
  companyName,
}) => {
  const { t } = useTranslation();
  
  const title = t('accountManager.modals.resetPassword');
  const content = t('accountManager.modals.resetContent');
  const subtitleItems = [{ key: t('accountManager.modals.companyName'), value: companyName }];

  const handleConfirm = () => {};

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={title}
      subtitleItems={subtitleItems}
      content={content}
      onConfirm={handleConfirm}
    />
  );
};

export default ResetPasswordModal;
