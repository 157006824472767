import { Box, Typography, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ContractRole, ContractStatusIndicator } from '@common/components';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.scss';
import { useGetCurrentContract } from 'src/account/contract/hooks/useGetCurrentContract';

interface ContractHeaderProps {
  contractId: string;
}

export const ContractHeader = ({ contractId }: ContractHeaderProps) => {
  const { t } = useTranslation();
  
  const navigate = useNavigate();
  const { contract, isLoading } = useGetCurrentContract(contractId);
  const handleBack = () => {
    navigate(-1);
  };

  if (isLoading || !contract) {
    return null;
  }

  return (
    <Box mb={2}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleBack} sx={{ mr: 1, p: 0 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="subtitle1">{t('account.contract.contractNo')} {contract.contract_number}</Typography>
        </Box>
        <Box display="flex" gap={0.5}>
          <ContractRole role={contract.customer_role} />
          <ContractStatusIndicator status={contract.deal_status} />
        </Box>
      </Box>
    </Box>
  );
};
