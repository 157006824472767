import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '@common/components/dialog/confirmationDialog';

interface DeleteCompanyModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  companyName: string;
}

const DeleteCompanyModal: React.FC<DeleteCompanyModalProps> = ({
  open,
  onClose,
  onConfirm,
  companyName,
}) => {
  const { t } = useTranslation();

  const title = t('accountManager.modals.removeCompany');
  const subtitleItems = [{ key: t('accountManager.modals.companyName'), value: companyName }];
  const content = t('accountManager.modals.deleteContent');

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={title}
      content={content}
      onConfirm={handleConfirm}
      subtitleItems={subtitleItems}
    />
  );
};

export default DeleteCompanyModal;
