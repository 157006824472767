import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import { DownloadingOutlined } from '@mui/icons-material';
import { useGetShipmentFiles } from '@app/hooks/useGetShipmentFiles';
import { DialogTitleModal, ImageSquare, PhotoSlider } from '@common/components';

interface UserPhotoModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  dealId: string;
  folder: string;
  fileIds: string[];
}

export const UserPhotoModal: React.FC<UserPhotoModalProps> = ({
  open,
  onClose,
  title,
  dealId,
  folder,
  fileIds,
}) => {
  const { t } = useTranslation();
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const { link } = useGetShipmentFiles(dealId, folder);

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index);

    setIsSliderOpen(true);
  };

  const handleSliderClose = () => {
    setIsSliderOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: 800,
          width: 800,
          height: 539,
        },
      }}
    >
      <DialogTitleModal title={title} onClose={onClose} />

      <DialogContent
        sx={{
          px: '24px',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '7px',
        }}
      >
        {fileIds &&
          fileIds.map((item, index) => (
            <ImageSquare
              key={item}
              fileId={item}
              dealId={dealId}
              hasDownloadFile
              hasRemoveFile={false}
              onImageClick={() => handleImageClick(index)}
              sx={{
                justifyContent: 'space-between',
                borderRadius: '4px',
              }}
            />
          ))}
      </DialogContent>
      <DialogActions sx={{ p: '24px', gap: 1, borderTop: '1px solid var(--light-white-color)' }}>
        <Button variant="outlined" startIcon={<DownloadingOutlined />} href={link}>
          {t('account.contract.downloadAll')}
        </Button>
      </DialogActions>

      {isSliderOpen && (
        <PhotoSlider
          isOpen={isSliderOpen}
          onClose={handleSliderClose}
          images={fileIds}
          dealId={dealId}
          currentImageIndex={currentImageIndex}
        />
      )}
    </Dialog>
  );
};
