import classes from './styles.module.scss';
import { no_case, people, money } from './assets';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const getSteps = (t: TFunction<"translation", undefined>) => [
  {
    number: '1',
    title: t('landing.howItWorks.step1.title'),
    content: (
      <>
        <div className={classes.textWrapper}>
          <p>
            Your client offers you a good price, but <br /> you can't agree on the fixation option:{' '}
            <br /> SPOT vs Formula <br /> Formula vs SPOT <br /> Supplier vs Consumer fixation
            option 
          </p>
          <p className={classes.centerText}>
            <strong>No deal or RISK, right?</strong>
          </p>
        </div>

        <div className={classes.icon}>
          <img src={no_case} alt="No deal icon" />
        </div>
      </>
    ),
  },
  {
    number: '2',
    title: (<>
      {t('landing.howItWorks.step2.title')}
      <span>{t('landing.hero.ex')}</span></>),
    content: (
      <>
        <div className={classes.textWrapper}>
          <p>
            Scrap<span>EX</span> buys product from the Supplier, sells it to the Consumer, and
            provides fixation option for both
          </p>
        </div>
        <div className={classes.icon}>
          <img src={people} alt="People icon" />
        </div>
      </>
    ),
  },
  {
    number: '3',
    title: t('landing.howItWorks.step3.title'),
    content: (
      <>
        <div className={classes.textWrapper}>
          <ul>
            <li>Supplier gets fixation option</li>
            <li>Consumer gets fixation option</li>
            <li>
              Scrap<span>EX</span> gets its commission for hedging.
            </li>
          </ul>
          <p className={classes.centerText}>
            <strong>Fair?</strong>
          </p>
        </div>

        <div className={classes.icon}>
          <img src={money} alt="Money bag icon" />
        </div>
      </>
    ),
  },
];

export const HowItWorks = () => {
  const { t } = useTranslation();

  const steps= getSteps(t);

  return (
    <section className={classes.howItWorks} id="services">
      <h2 className={classes.title}>How It Works</h2>
      <div className={classes.steps}>
        {steps.map((step, index) => (
          <div className={classes.stepCard} key={index}>
            <div className={classes.stepNumber}>{step.number}</div>
            <h3 className={classes.stepTitle}>{step.title}</h3>
            <div className={classes.stepContent}>{step.content}</div>
          </div>
        ))}
      </div>
    </section>
  );
};
