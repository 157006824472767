import { Box, Button, IconButton, List, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IShipmentDocuments, IShipmentInvoice, IShipmentUser } from '@app/types';
import {
  DataListItem,
  DrawerFooter,
  LoadingSkeleton,
  ScrapexGrayText,
  ScrapexSidebarTextH1,
  ShipmentStatusIndicator,
} from '@common/components';
import { AddButton } from '@common/components/display/buttons/addButton';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';

import { FilesField } from './components/filesField';

import {
  renderDateOrNoneInDrawer,
  renderEstimatedOrFinalWeightInDrawer,
  renderHedgingDetailsDrawer,
  renderValueOrNoneInDrawer,
} from '@app/utils';
//TODO: move it to common
import { InvoiceField } from './components/invoiceField';
import { OpenInNewOutlined } from '@mui/icons-material';
import { FileRow } from './components/fileRow';
import { useGetCurrentShipment } from '../table/hooks/useGetCurrentShipment';

interface IShipmentInfoDrawerProps {
  shipmentId: string;
  onClose: () => void;
  onExistingHedgeClick: (e: React.MouseEvent, hedgeId: string) => void;
  onInitializingHedgeClick: (shipmentId: string) => void;
}

export const UserShipmentInfoDrawer = ({
  shipmentId,
  onClose,
  onExistingHedgeClick,
  onInitializingHedgeClick,
}: IShipmentInfoDrawerProps) => {
  const { t } = useTranslation();
  
  //TODO: Refactor component
  const { shipment, isShipmentLoading } = useGetCurrentShipment(shipmentId);
  const contract = useSelector((state: RootState) => state.account.contracts.currentContract);

  if (isShipmentLoading) {
    return <LoadingSkeleton />;
  }

  const mappedFiles: Partial<Record<keyof IShipmentDocuments, string>> = {
    quality_certificate: shipment?.quality_certificate,
    packing_list: shipment?.packing_list,
    insurance_certificate: shipment?.insurance_certificate,
    survey_report: shipment?.survey_report,
    connaissement: shipment?.connaissement,
  };

  const getTitleByConnaissementType = (connaissementType: any) => {
    switch (connaissementType) {
      case 'bl':
        return 'BL';
      case 'telex':
        return 'Telex';
      case 'swb':
        return 'SWB';
      default:
        return 'Unknown Title';
    }
  };

  const fileFields: { field: keyof IShipmentDocuments; title: string }[] = [
    { field: 'connaissement', title: getTitleByConnaissementType(shipment?.connaissement_type) },
    { field: 'quality_certificate', title: t('account.contract.qualityCertificate')},
    { field: 'packing_list', title: t('account.contract.packingList')},
    { field: 'insurance_certificate', title: t('account.contract.insuranceCertificate')},
    { field: 'survey_report', title: t('account.contract.surveyReport')},
  ];

  const handleIconClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClose();
    if (shipment?.company_hedge?.id) {
      onExistingHedgeClick(e, shipment.company_hedge.id);
    } else {
      onInitializingHedgeClick(shipment!.id);
    }
  };

  return (
    <>
      {!isShipmentLoading && shipment && (
        <>
          <Box padding="0 40px 100px 40px">
            <ScrapexSidebarTextH1>{t('account.contract.shipmentInfo')}</ScrapexSidebarTextH1>
            <List sx={{ mb: '20px' }}>
              <DataListItem title={t('account.contract.shipmentStatus')}>
                <ShipmentStatusIndicator status={shipment.shipment_status} />
              </DataListItem>
              <DataListItem title={t('account.contract.ordinalNo')}>{shipment.ordinal_number}</DataListItem>
              <DataListItem title={t('account.contract.shipmentNo')}>
                {renderValueOrNoneInDrawer(shipment.shipment_number)}
              </DataListItem>

              <DataListItem title={t('account.contract.finalWeight')}>
                {renderEstimatedOrFinalWeightInDrawer(
                  shipment?.final_weight,
                  shipment.estimated_weight,
                  t
                )}
              </DataListItem>
              <DataListItem title={t('account.contract.hedging')}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography>{renderHedgingDetailsDrawer(t, shipment.company_hedge)}</Typography>
                  <IconButton onClick={handleIconClick}>
                    <OpenInNewOutlined fontSize="small" />
                  </IconButton>
                </Box>
              </DataListItem>
              <DataListItem title={t('account.contract.limitFixationDate')}>
                {renderDateOrNoneInDrawer(t, shipment.company_limit_fixation_date)}
              </DataListItem>

              <DataListItem title={t('account.contract.shipmentValueUsd')}>
                {renderValueOrNoneInDrawer(shipment.shipment_value)}
              </DataListItem>
              <DataListItem title={t('account.contract.photos')}>
                <FilesField
                  key="photos"
                  dealId={contract?.id}
                  folder={`${shipment.id}-photos`}
                  title={t('account.contract.photos')}
                  fileIds={shipment?.photos}
                  type="photo"
                />
              </DataListItem>
              <DataListItem title={t('account.contract.otherFiles')} hideDivider>
                <FilesField
                  key="company_other_files"
                  dealId={contract?.id}
                  folder={`${shipment.id}-${contract?.customer_role}_other_files`}
                  title={t('account.contract.otherFiles')}
                  fileIds={shipment?.company_other_files}
                  type="files"
                />
              </DataListItem>
              <ScrapexSidebarTextH1>{t('account.contract.claims')}</ScrapexSidebarTextH1>
              <DataListItem title={t('account.contract.claim')} hideDivider>
                <AddButton />
              </DataListItem>
              <ScrapexSidebarTextH1>{t('account.contract.invoices')}</ScrapexSidebarTextH1>
              {shipment?.company_invoices.length > 0 ? (
                <>
                  {shipment.company_invoices.map((invoice: IShipmentInvoice, index: number) => (
                    <InvoiceField
                      key={`invoice_${index}`}
                      dealId={contract?.id}
                      invoice={invoice}
                      hideDivider={index === shipment.company_invoices.length - 1}
                    />
                  ))}
                </>
              ) : (
                <Box textAlign="center" my={6}>
                  <ScrapexGrayText>{t('account.contract.noData')}</ScrapexGrayText>
                </Box>
              )}
              <ScrapexSidebarTextH1>{t('account.contract.shipmentDocuments')}</ScrapexSidebarTextH1>

              {Object.values(mappedFiles).some((fileId) => fileId) || shipment?.other_files ? (
                <>
                  {fileFields.map((fileField) => (
                    <DataListItem key={fileField.field} title={fileField.title}>
                      <FileRow
                        fileId={mappedFiles[fileField.field] as string}
                        dealId={contract?.id}
                        hasDownloadFile
                      />
                    </DataListItem>
                  ))}
                  <DataListItem title={t('account.contract.other')} hideDivider>
                    <FilesField
                      key="other_files"
                      dealId={contract?.id}
                      folder={`${shipment.id}-other`}
                      title={t('account.contract.other')}
                      fileIds={shipment?.other_files}
                      type="files"
                    />
                  </DataListItem>
                </>
              ) : (
                <Box textAlign="center" my={6}>
                  <ScrapexGrayText>{t('account.contract.noData')}</ScrapexGrayText>
                </Box>
              )}
            </List>
          </Box>
        </>
      )}
      <DrawerFooter>
        <Button variant="contained" onClick={onClose}>
          {t('account.contract.close')}
        </Button>
      </DrawerFooter>
    </>
  );
};
