import { Box, IconButton, InputBase } from '@mui/material';
import { DeleteOutlined, Close, Search } from '@mui/icons-material';
import { useState } from 'react';
import classes from './styles.module.scss';

interface MobileSearchProps {
  onClose: () => void;
  onSearch: (query: string) => void;
}

export const MobileSearch = ({ onClose, onSearch }: MobileSearchProps) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleClear = () => {
    setSearchQuery('');
    onSearch('');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
    onSearch(value);
  };

  return (
    <Box className={classes.searchContainer}>
      <InputBase
        className={classes.input}
        placeholder="Search11"
        value={searchQuery}
        onChange={handleChange}
        autoFocus
        sx={{
          input: { color: 'white' },
          '& .MuiInputBase-input::placeholder': { color: '#8592A8' },
        }}
        startAdornment={<Search sx={{ color: '#8592A8', ml: 1, mr: 1 }} />}
        endAdornment={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {searchQuery && (
              <IconButton size="small" onClick={handleClear}>
                <DeleteOutlined sx={{ color: '#8592A8' }} />
              </IconButton>
            )}
            <IconButton size="small" onClick={onClose}>
              <Close sx={{ color: '#8592A8' }} />
            </IconButton>
          </Box>
        }
      />
    </Box>
  );
};
