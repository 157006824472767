import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useRef, useState } from 'react';
import { AddOutlined } from '@mui/icons-material';
import { FileRow } from '../fileRow';
import { useUploadShipmentFiles } from '@app/hooks';

interface PdfFileSelectorProps {
  onPdfIdSelect: (pdfId: string) => void;
  isFileExists?: boolean;
  fileIdProp?: string;
  dealId?: string;
  folder: string;
  hasRemoveFile?: boolean;
}

export const PdfFileSelector: React.FC<PdfFileSelectorProps> = ({
  onPdfIdSelect,
  fileIdProp = '',
  dealId = '',
  folder,
  hasRemoveFile,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileId, setFileId] = useState<string | undefined>(fileIdProp);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  

  const { upload, loading } = useUploadShipmentFiles();

  const handleReloadFile = () => {
    setSelectedFile(null);
    fileInputRef?.current?.click();
  };

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      try {
        const uploadedId = await upload(files[0], dealId, folder);
        setSelectedFile(files[0]);
        onPdfIdSelect(uploadedId);
        setFileId(uploadedId);
      } catch (err) {
        console.error('Upload failed:', err);
      }
    }
  };

  const handleRemoveFileClick = () => {
    setSelectedFile(null);
    setFileId('');
    onPdfIdSelect('');

    const fileInput = fileInputRef.current;

    if (fileInput) {
      fileInput.value = '';
    }
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {fileId ? (
            <FileRow
              fileId={fileId}
              dealId={dealId}
              onReuploadClick={handleReloadFile}
              onClearClick={handleRemoveFileClick}
              hasRemoveFile={hasRemoveFile}
            />
          ) : (
            <Button
              variant="outlined"
              color="warning"
              onClick={() => fileInputRef?.current?.click()}
              startIcon={<AddOutlined />}
            >
              {t('accountManager.shipment.add')}
            </Button>
          )}
        </>
      )}
      <input
        ref={fileInputRef}
        hidden
        multiple={false}
        type="file"
        id="file"
        name="file"
        accept="application/pdf"
        onChange={handleFileInputChange}
      />
    </>
  );
};
