import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/store';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, FormControl } from "@mui/material";
import { SelectChangeEvent } from "@mui/material";

import classes from './styles.module.scss';
import { Box, Stack } from '@mui/material';
import { LayoutProfile, LayoutSettings } from './components';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/ReactToastify.min.css';
import { NotificationCenter } from 'src/account/notifications/notificationCenter';
import { ScrapexWidget } from '../widget';

export const Header = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const role = useSelector((state: RootState) => state.auth.auth.role);
  
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("en");
  
  const isAccountManager = role === 'accountManager';

  const handleChange = (event: SelectChangeEvent<string>) => {
    const newLang = event?.target?.value;
    setLanguage(newLang);
    i18n.changeLanguage(newLang)
  };

  useEffect(() => {
    setLanguage(i18n.language)
  }, [i18n.language]);

  return (
    <header className={classes.header}>
      <Box sx={{ width: '620px', height: '100%', background: '#fff', padding: '20px 40px' }}>
        <ScrapexWidget />
      </Box>
      <ToastContainer
        style={{
          width: '400px',
        }}
      />
      <div className={classes.controls}>
      {!isAccountManager && <FormControl size="small"  >
           <Select value={language} onChange={handleChange} displayEmpty sx={{color: 'white', border: "1px solid white"}}>
            <MenuItem value="en">En</MenuItem>
            <MenuItem value="zh">中文</MenuItem>
          </Select>
        </FormControl>}
        <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {role === 'trader' && <NotificationCenter />}
          <LayoutProfile />
          <LayoutSettings />
        </Stack>
      </div>
    </header>
  );
};
