import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab, Typography } from '@mui/material';
import { TabTitle } from '../tabTitle';

interface NotificationTabsProps {
  tabValue: number;
  handleChange: (event: React.SyntheticEvent<unknown>, newValue: number) => void;
  unreadHedgeCount: number;
}

export const NotificationTabs: React.FC<NotificationTabsProps> = ({
  tabValue,
  handleChange,
  unreadHedgeCount,
}) => {
  const { t } = useTranslation();
  
  const handleTabChange = (event: React.SyntheticEvent<unknown>, newValue: number) => {
    event.stopPropagation();
    handleChange(event, newValue);
  };

  return (
    <Tabs value={tabValue} onChange={handleTabChange} sx={{ display: 'flex' }} variant="fullWidth">
      <Tab
        sx={{
          '& .MuiTab-label': {
            padding: 0,
          },
        }}
        label={<TabTitle title={t('account.contract.HEDGE_NOTIFICATIONS')} count={unreadHedgeCount} />}
      />
      <Tab label={<Typography fontSize={14}>{t('account.contract.HISTORY')}</Typography>} />
    </Tabs>
  );
};
