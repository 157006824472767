import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { IHedge } from '@app/types';
import { formatDate, formatTime } from '@app/utils';
import { ContractRole, HedgingStatusIndicator } from '@common/components';
import { ScrapexGrayText } from '@common/components';
import classes from './styles.module.scss';

interface HedgeCardProps {
  hedge: IHedge;
  onClick: () => void;
}

export const HedgeCard = ({ hedge, onClick }: HedgeCardProps) => {
  const { t } = useTranslation();
  
  return (
    <Card
      className={classes.card}
      onClick={onClick}
      sx={{ boxShadow: 'none', borderRadius: 2, border: '1px solid #E0E0E0' }}
    >
      <CardContent sx={{ p: 1 }}>
        <Box className={classes.header}>
          <Box className={classes.headerLeft}>
            <Typography variant="subtitle2">{t('account.contract.hedgeNo')} {hedge.hedge_number}</Typography>
            <Typography variant="caption" color="textSecondary">
              {t('account.contract.contractNo')} {hedge.contract_number}
            </Typography>
          </Box>
          <Box display="flex" gap={1}>
            <ContractRole role={hedge.hedge_shipments?.[0]?.role} />
            <HedgingStatusIndicator status={hedge.hedge_status} />
          </Box>
        </Box>

        <Box className={classes.content}>
          <Box className={classes.row}>
            <Typography variant="body2">{t('account.contract.dateOfCreation')}</Typography>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2">{formatDate(hedge.request_time)}</Typography>
              <Typography variant="caption" color="textSecondary">
                {formatTime(hedge.request_time)}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.row}>
            <Typography variant="body2">{t('account.contract.executionDate')}</Typography>
            <Box sx={{ textAlign: 'right' }}>
              {hedge.filled_time ? (
                <>
                  <Typography variant="body2">{formatDate(hedge.filled_time)}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    {formatTime(hedge.filled_time)}
                  </Typography>
                </>
              ) : (
                <ScrapexGrayText>{t('account.contract.none')}</ScrapexGrayText>
              )}
            </Box>
          </Box>

          <Box className={classes.row}>
            <Typography variant="body2">{t('account.contract.type')}:</Typography>
            <Typography variant="body2">{hedge.hedge_type.toUpperCase()}</Typography>
          </Box>

          <Box className={classes.row}>
            <Typography variant="body2">{t('account.contract.shipments')}:</Typography>
            <Typography variant="body2">{hedge.hedge_shipments.length}</Typography>
          </Box>

          <Box className={classes.row}>
            <Typography variant="body2">{t('account.contract.productMetal')}:</Typography>
            <Typography variant="body2">{hedge.product}</Typography>
          </Box>

          <Box className={classes.row}>
            <Typography variant="body2">{t('account.contract.fixed')} LME:</Typography>
            <Typography variant="body2">
              {hedge.fixed_exchange || <ScrapexGrayText>{t('account.contract.none')}</ScrapexGrayText>}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
