import { useLocation } from 'react-router-dom';
import { ISidebarNavigationItem, SidebarNavigationItem } from './components/navigationItem';

import classes from './styles.module.scss';
import { Inbox, BugReport } from '@mui/icons-material';
import { AccountManagerRoutes, AccountRoutes } from '@app/types/navigation';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { TFunction } from 'i18next';

// ACCOUNT_NAVIGATION/
export const getAccountNavigation = (t: TFunction) : ISidebarNavigationItem[] => [
  {
    link: AccountRoutes.COMPANY_CONTRACTS,
    title: t('account.contract.myContracts'),
    icon: <Inbox />,
  },
  {
    link: AccountRoutes.COMPANY_HEDGING,
    title: t('account.contract.hedgeHistory'),
    icon: <Inbox />,
  },
];

export const ADMIN_NAVIGATION: ISidebarNavigationItem[] = [
  {
    link: AccountManagerRoutes.ACCOUNT_MANAGER + '/' + AccountManagerRoutes.HEDGE_REQUESTS_TEST,
    title: 'TEST Requests',
    icon: <BugReport />,
    highlight: true,
  },
  {
    link: AccountManagerRoutes.ACCOUNT_MANAGER + '/' + AccountManagerRoutes.COMPANIES,
    title: 'Companies',
    icon: <Inbox />,
  },
  {
    link: AccountManagerRoutes.ACCOUNT_MANAGER + '/' + AccountManagerRoutes.CONTRACTS_DASHBOARD,
    title: 'Contracts1',
    icon: <Inbox />,
  },
  {
    link: AccountManagerRoutes.ACCOUNT_MANAGER + '/' + AccountManagerRoutes.HEDGE_REQUESTS,
    title: 'Hedge Requests',
    icon: <Inbox />,
  },

  {
    link: AccountManagerRoutes.ACCOUNT_MANAGER + '/' + AccountManagerRoutes.HEDGE_AT_WORK,
    title: 'At Work',
    icon: <Inbox />,
  },
  {
    link: AccountManagerRoutes.ACCOUNT_MANAGER + '/' + AccountManagerRoutes.UNHEDGED,
    title: 'Unhedged',
    icon: <Inbox />,
  },
  {
    link: AccountManagerRoutes.ACCOUNT_MANAGER + '/' + AccountManagerRoutes.HEDGE_HISTORY,
    title: 'Hedge History',
    icon: <Inbox />,
  },
];
export const SidebarNavigation = ({t}: {t:TFunction}) => {
  const location = useLocation();
  const role = useSelector((state: RootState) => state.auth.auth.role);
  const navigationList = role === 'trader' ? getAccountNavigation(t) : ADMIN_NAVIGATION;
  return (
    <div className={classes.navigation}>
      <ul>
        {navigationList.map((item) => (
          <SidebarNavigationItem
            key={item.title}
            isActive={location.pathname.includes(item.link)}
            title={item.title}
            link={item.link}
            icon={item.icon}
            highlight={item.highlight}
          />
        ))}
      </ul>
    </div>
  );
};
