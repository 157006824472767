import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableContainer } from '@mui/material';
import { useGetShipments } from './hooks/useGetShipments';
import { DrawerGeneral, LoadingSkeleton } from '@common/components';
import { DataGridPremium, GridRowId, GridRowParams } from '@mui/x-data-grid-premium';
import { HedgingStatus, IShipmentUser } from '@app/types';
import { UserShipmentInfoDrawer } from '../shipmentInfoDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/store';
import { actions } from 'src/account/reducer/hedging/slice';
import { HedgeRequestDrawer } from '../../hedging/components/hedgeRequestDrawer';
import { useOpenShipmentsDrawer } from './hooks/useOpenShipmentDrawer';
import { generateShipmentColumns, generateShipmentRowsData } from './data';
import { useParams } from 'react-router-dom';
import { getFilesUrl } from '@app/utils';
import { TableHeader } from 'src/account/common';
import { useDropActiveHedge } from 'src/account/contract/hooks/useDropActiveHedge';

export const ContractShipmentsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const selectMode = useSelector((state: RootState) => state.account.hedging.isSelectMode);
  const hedge = useSelector((state: RootState) => state.account.hedging.currentHedge);
  const { contractId } = useParams();
  const { checkAndDropActiveHedge } = useDropActiveHedge(contractId as string);

  const {
    shipmentInfo,
    hedgeRequest,
    openShipmentInfoHandler,
    closeShipmentInfoHandler,
    openHedgeRequestHandler,
    closeHedgeRequestHandler,
    closeAllHandler,
  } = useOpenShipmentsDrawer();
  const { shipments, isLoading, error } = useGetShipments(contractId as string);

  const [selectedShipment, setSelectedShipment] = useState<IShipmentUser | null>(null);
  const [selectedHedge, setSelectedHedge] = useState<string | null>(null);
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  const [shipmentIdForHedge, setShipementIdForHedge] = useState<string | null>(null);
  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);

  useEffect(() => {
    if (selectMode) {
      setSelectedRows([]);
    }
  }, [selectMode]);

  useEffect(() => {
    checkAndDropActiveHedge();
  }, []);

  const handleDownloadFiles = async (shipmentId: string) => {
    const filesUrl = await getFilesUrl(shipmentId, contractId as string, 'user');
    window.open(filesUrl, '_self');
  };

  const handleFileIconClick = (event: React.MouseEvent, shipmentId: string) => {
    event.stopPropagation();
    handleDownloadFiles(shipmentId);
  };

  const handleHedgeIconClick = (event: React.MouseEvent, hedgeId: string) => {
    event.stopPropagation();
    setSelectedHedge(hedgeId);
    openHedgeRequestHandler();
  };

  const { columns } = generateShipmentColumns({
    onHandleFileIconClick: handleFileIconClick,
    onHandleHedgeIconClick: handleHedgeIconClick,
    hoveredRowId,
  });

  const { rows } = generateShipmentRowsData(shipments);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    const rowId = event.currentTarget.dataset.id;
    setHoveredRowId(rowId as string);
  };

  const handleMouseLeave = () => {
    setHoveredRowId(null);
  };

  const handleRowClick = (params: GridRowParams) => {
    if (!selectMode) {
      setSelectedShipment(params.row);
      openShipmentInfoHandler();
    }
  };

  const handleRowSelectionChange = (selectionModel: GridRowId[]) => {
    if (selectMode) {
      setSelectedRows(selectionModel);
      dispatch(actions.selectShipments(selectionModel));
    }
  };

  const handleInitializingHedge = (shipmentId: string) => {
    setShipementIdForHedge(shipmentId);
    openHedgeRequestHandler();
  };

  const handleCloseHedgeRequestDrawer = () => {
    closeHedgeRequestHandler();
  };

  const handleCloseShipmentInfoDrawer = () => {
    closeShipmentInfoHandler();
    setSelectedHedge(null);
  };

  return (
    <>
      {isLoading && !columns && <LoadingSkeleton />}
      {error && <p>{t('account.contract.error')}: {error.message}</p>}
      {!isLoading && !error && columns && (
        <TableContainer sx={{ background: 'var(--white-color)' }}>
          <div style={{ height: '60vh' }}>
            <DataGridPremium
              sx={{
                background: '#fff',
                border: 'none',
                '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeaders': {
                  color: '#8592A8',
                  border: 'none',
                },
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                },
              }}
              columnHeaderHeight={40}
              rows={rows}
              columns={columns}
              onRowClick={handleRowClick}
              checkboxSelection={selectMode}
              rowSelectionModel={selectedRows}
              isRowSelectable={(params: GridRowParams) => !params.row.company_hedge}
              onRowSelectionModelChange={handleRowSelectionChange}
              disableColumnFilter
              hideFooter
              slotProps={{
                row: {
                  onMouseEnter: handleMouseEnter,
                  onMouseLeave: handleMouseLeave,
                },
                toolbar: {
                  showQuickFilter: true,
                  title: t('account.contract.shipments'),
                },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'ordinal_number', sort: 'asc' }],
                },
              }}
              sortingOrder={['desc', 'asc']}
              slots={{ toolbar: TableHeader }}
              disableColumnMenu
              // disableRowSelectionOnClick
            />
          </div>
          <DrawerGeneral onClose={closeAllHandler} open={shipmentInfo.isOpen}>
            {shipmentInfo.isOpen && selectedShipment && (
              <UserShipmentInfoDrawer
                shipmentId={selectedShipment.id}
                onClose={handleCloseShipmentInfoDrawer}
                onExistingHedgeClick={(event, hedgeId) => handleHedgeIconClick(event, hedgeId)}
                onInitializingHedgeClick={(shipmentId) => handleInitializingHedge(shipmentId)}
              />
            )}
          </DrawerGeneral>

          <DrawerGeneral
            onClose={hedge?.hedge_status === HedgingStatus.Waiting ? undefined : closeAllHandler}
            open={hedgeRequest.isOpen}
          >
            {hedgeRequest.isOpen && (
              <HedgeRequestDrawer
                hedgeId={selectedHedge ? selectedHedge : null}
                shipmentsIds={shipmentIdForHedge ? [shipmentIdForHedge] : null}
                onClose={
                  hedge?.hedge_status === HedgingStatus.Waiting
                    ? undefined
                    : handleCloseHedgeRequestDrawer
                }
              />
            )}
          </DrawerGeneral>
        </TableContainer>
      )}
    </>
  );
};
