import { useTranslation } from 'react-i18next';
import { Box, Typography, Button, Dialog, TextField, IconButton } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import classes from './styles.module.scss';

interface ExchangeLevelDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (level: string) => void;
}

export const ExchangeLevelDialog = ({ open, onClose, onSubmit }: ExchangeLevelDialogProps) => {
  const { t } = useTranslation();
  const [level, setLevel] = useState('');

  const handleDone = () => {
    if (level) {
      onSubmit(level);
    }
    onClose();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(',', '.');
    if (/^\d*\.?\d*$/.test(value)) {
      setLevel(value);
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      classes={{ paper: classes.dialog }}
      PaperProps={{ sx: { borderRadius: '24px' } }}
    >
      <Box className={classes.container}>
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 0 }} size="medium">
          <CloseIcon />
        </IconButton>
        <Box className={classes.header}>
          <Typography variant="h6">{t('account.contract.enterExchangeLevel')}</Typography>
        </Box>

        <TextField
          fullWidth
          placeholder={t('account.contract.enterYourExchangeLevel')}
          value={level}
          onChange={handleChange}
          className={classes.input}
          type="text"
          inputProps={{
            inputMode: 'decimal',
            pattern: '[0-9]*[.,]?[0-9]*',
          }}
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleDone}
          disabled={!level}
        >
          {t('account.contract.DONE')}
        </Button>
      </Box>
    </Dialog>
  );
};
