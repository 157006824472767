import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '@common/components/dialog/confirmationDialog';
import { HedgeType as HedgeTypeMap, HedgeTypeStringMap } from '../../helpers';
import { HedgeType } from '@app/types/api';
import { Box, InputLabel, TextField, Typography } from '@mui/material';
import { useState } from 'react';

interface HedgeRejectModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (message: string) => void;
  hendgeNumber?: string;
  hedgeType?: HedgeType;
}

const Content = ({
  handleChangeMessage,
}: {
  handleChangeMessage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { t } = useTranslation();
  
  return (
    <>
      <Typography>{t('hedge.doYouReallyWant')}</Typography>
      <Box>
        <InputLabel sx={{ margin: '14px 0 4px 0', fontSize: '14px', fontWeight: 500 }}>
      
        {t('hedge.adminComment')} ({t('hedge.optional')})
        </InputLabel>
        <TextField
          autoFocus
          multiline
          maxRows={4}
          placeholder={t('hedge.enterYourComment')}
          type="text"
          fullWidth
          onChange={handleChangeMessage}
        />
      </Box>
    </>
  );
};

export const HedgeRejectModal: React.FC<HedgeRejectModalProps> = ({
  open,
  onClose,
  onConfirm,
  hendgeNumber,
  hedgeType = HedgeTypeMap.ACM,
}) => {
  const { t } = useTranslation();
  const [rejectMessage, setRejectMessage] = useState<string>('');

  const title = t('hedge.rejectOrder');
  const subtitleItems = [
    { key: 'Hedge No.', value: hendgeNumber },
    { key: 'Hedge Type:', value: HedgeTypeStringMap[hedgeType as HedgeType] },
  ];

  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRejectMessage(event.target.value);
  };

  const handleConfirm = () => {
    onConfirm(rejectMessage);
    onClose();
  };

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={title}
      subtitleItems={subtitleItems}
      content={<Content handleChangeMessage={handleChangeMessage} />}
      onConfirm={handleConfirm}
    />
  );
};
