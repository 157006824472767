import { useTranslation } from 'react-i18next';
import { CommonTabs, DataListItem, ScrapexGrayText } from '@common/components';
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { HedgeTabType, HedgeType } from '../../helpers';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { DotDecimalInput } from '@common/components/display/hedging/input';

interface HedgeRequestTabsProps {
  selectedTab?: HedgeTabType;
  onTabChange: (newValue: HedgeTabType) => void;
  onHandleUpdate: (value: string) => void;
  tpExchangeLevel: string;
  ringDate: Dayjs | null;
  onHandleDate: (date: Dayjs) => void;
  onHandleOrderTypeChange: (type: HedgeType) => void;
  orderType: HedgeType;
}

const orderTypeItems = [
  { value: 'tp', label: 'Take Profit' },
  { value: 'olme', label: 'Official LME' },
];

export const ContentACM = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <DataListItem title={t('account.contract.currentLmeOfferUsd')}>
        <ScrapexGrayText>{t('account.contract.none')}</ScrapexGrayText>
      </DataListItem>
      <DataListItem title={t('account.contract.fixedLmeUsdMt')}>
        <ScrapexGrayText>{t('account.contract.none')}</ScrapexGrayText>
      </DataListItem>
      <DataListItem title={t('account.contract.finalUnitPriceUsdMt')}>
        <ScrapexGrayText>{t('account.contract.none')}</ScrapexGrayText>
      </DataListItem>
    </>
  );
};

export const HedgeRequestTabs: React.FC<HedgeRequestTabsProps> = ({
  selectedTab = HedgeTabType.ACM,
  onTabChange,
  onHandleUpdate,
  tpExchangeLevel,
  ringDate,
  onHandleDate,
  onHandleOrderTypeChange,
  orderType,
}) => {
  const { t } = useTranslation();
  
  const handleTpExchangeLevelChange = (value: string) => {
    onHandleUpdate(value);
  };

  const tabs = [
    {
      title: `${t('account.contract.atTheCurrentMarket')} (3-month)`,
      content: (
        <>
          <ContentACM />
        </>
      ),
    },
    {
      title: `${t('account.contract.orders')} (3-month)`,
      content: (
        <>
          <DataListItem title={t('account.contract.orderType')}>
            <FormControl fullWidth>
              <Select
                value={orderType}
                onChange={(e) => onHandleOrderTypeChange(e.target.value as HedgeType)}
                variant="standard"
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  '&.MuiInputBase-root': {
                    '& .MuiInput-input:focus': {
                      backgroundColor: 'transparent',
                    },
                  },
                }}
                disableUnderline
              >
                {orderTypeItems.map((type) => (
                  <MenuItem key={type.value} value={type.value} selected={type.value === orderType}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DataListItem>
          {orderType === HedgeType.TakeProfit && (
            <>
              <DataListItem title={t('account.contract.takeProfitExchange')}>
                <Box display="flex" alignItems={'center'} gap={1}>
                  LME
                  <DotDecimalInput
                    value={tpExchangeLevel}
                    onChange={handleTpExchangeLevelChange}
                    placeholder="Enter Exchange Level"
                  />
                </Box>
              </DataListItem>
              <DataListItem title={t('account.contract.date')}>GTC</DataListItem>
            </>
          )}
          {orderType === HedgeType.OLME && (
            <DataListItem title={t('account.contract.date')}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <DatePicker
                  slotProps={{ textField: { size: 'small' } }}
                  format="DD/MM/YYYY"
                  disablePast
                  onChange={(date) => onHandleDate(date as Dayjs)}
                  value={ringDate}
                />
              </LocalizationProvider>
            </DataListItem>
          )}
        </>
      ),
    },
  ];

  return (
    <CommonTabs
      tabs={tabs}
      selectedTab={selectedTab}
      onTabChange={(newValue: number) => onTabChange(newValue)}
    />
  );
};
