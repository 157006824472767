import { useEffect, useState, useRef } from 'react';
import { Divider } from '@mui/material';
import classes from './styles.module.scss';
import { useTranslation } from 'react-i18next';

interface CounterProps {
  targetNumber: number;
  suffix?: string;
  prefix?: string;
}

const Counter: React.FC<CounterProps> = ({ targetNumber, suffix = '', prefix = '' }) => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const counterRef = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 },
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      let start = 0;
      const duration = 1000;
      const increment = Math.ceil(targetNumber / (duration / 50));
      const timer = setInterval(() => {
        start += increment;
        if (start >= targetNumber) {
          start = targetNumber;
          clearInterval(timer);
        }
        setCount(start);
      }, 50);

      return () => clearInterval(timer);
    }
  }, [isVisible, targetNumber]);

  return (
    <div className={classes.counterContainer} ref={counterRef}>
      <div className={classes.counter}>
        {prefix}
        {count.toLocaleString()}
        {suffix}
      </div>
    </div>
  );
};

export const Numbers: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className={classes.numbers}>
      <div className={classes.title}>
        <h2>{t('landing.numbers.label')}</h2>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.item}>
          <Counter targetNumber={15} />
          <Divider color="gray" sx={{ width: '100%' }} />
          <p>{t('landing.numbers.companies')}</p>
        </div>
        <div className={classes.item}>
          <Counter targetNumber={2470} suffix=" MT" />
          <Divider color="gray" sx={{ width: '100%' }} />
          <p>{t('landing.numbers.metrics')}</p>
        </div>
        <div className={classes.item}>
          <Counter targetNumber={15.7} prefix="$ " suffix=" M" />
          <Divider color="gray" sx={{ width: '100%' }} />
          <p>{t('landing.numbers.amount')}</p>
        </div>
      </div>
    </section>
  );
};
