import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, OutlinedInput } from '@mui/material';
import { DoneAllOutlined, CloseOutlined, EditOutlined } from '@mui/icons-material';
import { ScrapexGrayText, ScrapexSidebarMediumTextH2 } from '@common/components';

interface EditFinalWeightProps {
  isFinalWeight?: boolean;
  estimatedWeight?: number;
  value: number;
  onSubmit: (newValue: number) => void;
}

const outlinedInputStyles = () => {
  return {
    input: {
      lineHeight: '20px',
      height: 'auto',
      padding: '8px !important',
    },
    fieldset: {
      border: `1px solid 'var(--other-300)'`,
    },
  };
};

export const EditFinalWeight: React.FC<EditFinalWeightProps> = ({
  value,
  isFinalWeight,
  onSubmit,
  estimatedWeight,
}) => {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState<number | string>(value || '');
  const [isEstimated, setIsEstimated] = useState(!isFinalWeight);

  const { t } = useTranslation();

  useEffect(() => {
    setInputValue(value || 0);
  }, [value]);

  const handleDoneClick = () => {
    const newValue = parseFloat(inputValue as string);

    if (newValue === 0) {
      onSubmit(newValue);
      setInputValue(estimatedWeight || 0);
      setIsEstimated(true);
    } else {
      const roundedValue = Math.round(newValue * 1000) / 1000;
      onSubmit(roundedValue);
      setInputValue(roundedValue);
      setIsEstimated(false);
    }

    setEditing(false);
  };

  const handleCancelClick = () => {
    setEditing(false);
    setInputValue(value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.replace(/^\.+|[^0-9.]|\.(?=.*\.)/g, ''); //allow only digits and dot

    setInputValue(inputValue);
  };

  const handleEditIconClick = () => {
    setEditing(true);
  };

  return (
    <>
      {editing ? (
        <>
          <OutlinedInput
            value={inputValue}
            type="text"
            onChange={handleInputChange}
            sx={outlinedInputStyles}
            inputProps={{
              type: 'text',
              pattern: '[0-9.]*',
              inputMode: 'numeric',
            }}
          />
          <IconButton onClick={handleDoneClick} color="success" disabled={!inputValue}>
            <DoneAllOutlined />
          </IconButton>
          <IconButton onClick={handleCancelClick} color="error">
            <CloseOutlined />
          </IconButton>
        </>
      ) : (
        <>
          {inputValue && (
            <Box display="flex" flexDirection="row" justifyContent="end">
              <Box display="flex" flexDirection="column" justifyContent="center">
                <ScrapexSidebarMediumTextH2>{inputValue}</ScrapexSidebarMediumTextH2>
                {isEstimated && <ScrapexGrayText>{t('accountManager.shipment.estimated')}</ScrapexGrayText>}
              </Box>

              <IconButton onClick={handleEditIconClick}>
                <EditOutlined fontSize="small" />
              </IconButton>
            </Box>
          )}
        </>
      )}
    </>
  );
};
