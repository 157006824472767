import { useTranslation } from 'react-i18next';
import { IContractAdmin, ICreateContract } from '@app/types';
import { Box, Typography } from '@mui/material';
import { PdfFileSelector } from 'src/accountManager/contracts/components/createContractDrawer/pdfFileSelector';

interface ContractPdfProps {
  role: string;
  fileId: string;
  contract: IContractAdmin;
  onUpdateContractWithFiles: (data: any) => void;
}

const RoleMap = {
  seller: 'Seller',
  buyer: 'Buyer',
};

export const ContractPdf = ({
  role,
  fileId,
  contract,
  onUpdateContractWithFiles,
}: ContractPdfProps) => {
  const { t } = useTranslation();
  
  return (
    <Box sx={{ width: '280px' }}>
      <Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'var(--other-500)' }}>
        {RoleMap[role as keyof typeof RoleMap]} {t('accountManager.contractHeader.contract')}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <PdfFileSelector
          key={role}
          onPdfIdSelect={(pdfId) => {
            onUpdateContractWithFiles({
              contractId: contract.id,
              contractType: role,
              contractFile: pdfId,
            });
          }}
          fileIdProp={fileId}
        />
      </Box>
    </Box>
  );
};
