import { useTranslation } from 'react-i18next';
import { Box, Typography, Button, Dialog } from '@mui/material';
import { DoneAllOutlined } from '@mui/icons-material';
import { HedgeType } from 'src/account/contract/components/shipments/hedging/components/hedgeRequestDrawer/helpers';
import classes from './styles.module.scss';

interface OrderTypeDialogProps {
  open: boolean;
  selectedType: HedgeType;
  onClose: () => void;
  onSelect: (type: HedgeType) => void;
}

export const OrderTypeDialog = ({
  open,
  selectedType,
  onClose,
  onSelect,
}: OrderTypeDialogProps) => {
  const { t } = useTranslation();
  
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
      PaperProps={{ sx: { borderRadius: '24px' } }}
    >
      <Box className={classes.container}>
        <Typography variant="h6" className={classes.title}>
        {t('account.contract.selectOrderType')}
        </Typography>

        <Box className={classes.options}>
          <Box className={classes.option} onClick={() => onSelect(HedgeType.TakeProfit)}>
            <Typography variant="body1">{t('account.contract.takeProfit')} (TP)</Typography>
            {selectedType === HedgeType.TakeProfit && <DoneAllOutlined color="success" />}
          </Box>
          <Box className={classes.option} onClick={() => onSelect(HedgeType.OLME)}>
            <Typography variant="body1">OLME</Typography>
            {selectedType === HedgeType.OLME && <DoneAllOutlined color="success" />}
          </Box>
        </Box>

        <Button variant="contained" fullWidth onClick={onClose}>
          {t('account.contract.selectOrderType')}
        </Button>
      </Box>
    </Dialog>
  );
};
