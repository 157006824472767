import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, OutlinedInput } from '@mui/material';
import { DoneAllOutlined, CloseOutlined, AddOutlined, EditOutlined } from '@mui/icons-material';
import { ScrapexSidebarMediumTextH2 } from '@common/components';

interface EditInputFieldProps {
  value?: string;
  onSubmit: (newValue: string) => void;
}

const outlinedInputStyles = () => {
  return {
    input: {
      lineHeight: '20px',
      height: 'auto',
      padding: '8px !important',
    },
    fieldset: {
      border: `1px solid 'var(--other-300)'`,
    },
  };
};

export const EditInputField: React.FC<EditInputFieldProps> = ({ value, onSubmit }) => {
  const { t } = useTranslation();
  
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState<string | undefined>(value);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleDoneClick = () => {
    onSubmit(inputValue || '');
    setEditing(false);
    setInputValue(inputValue);
  };

  const handleCancelClick = () => {
    setEditing(false);
    setInputValue(value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleEditIconClick = () => {
    setEditing(true);
  };

  return (
    <>
      {editing ? (
        <>
          <OutlinedInput value={inputValue} onChange={handleInputChange} sx={outlinedInputStyles} />
          <IconButton onClick={handleDoneClick} color="success">
            <DoneAllOutlined />
          </IconButton>
          <IconButton onClick={handleCancelClick} color="error">
            <CloseOutlined />
          </IconButton>
        </>
      ) : (
        <>
          {inputValue ? (
            <>
              <ScrapexSidebarMediumTextH2>{inputValue}</ScrapexSidebarMediumTextH2>
              <IconButton onClick={handleEditIconClick}>
                <EditOutlined fontSize="small" />
              </IconButton>
            </>
          ) : (
            <Button
              onClick={handleEditClick}
              variant="outlined"
              color="warning"
              startIcon={<AddOutlined />}
            >
              {t('accountManager.shipment.add')}
            </Button>
          )}
        </>
      )}
    </>
  );
};
