import { Box, Button, List, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CompanyStatusIndicator } from '../status';
import FreezeCompanyModal from '../confirmationModals/freezeCompanyModal';
import { useState } from 'react';
import { useGetCompanyInfo } from './hooks/useGetCompanyInfo';
import { NavigateIconButton } from 'src/accountManager/common/navigateIconButton';
import {
  DataListItem,
  DrawerFooter,
  LoadingSkeleton,
  ScrapexSidebarMediumTextH2,
  ScrapexSidebarTextH1,
} from '@common/components';
import { CompanyStatus } from '@app/types';
import { formatDate } from '@app/utils';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@app/store';
import { getCompaniesThunk, updateCompanyThunk } from 'src/accountManager/reducer/companies/thunks';

interface ICompanyInfoDrawerProps {
  companyId: string;
  onOpenEdit: () => void;
  onClose: () => void;
}

export const CompanyInfoDrawer = ({ companyId, onOpenEdit, onClose }: ICompanyInfoDrawerProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [freezeCompanyModalOpen, setFreezeCompanyModalOpen] = useState(false);
  const { currentCompany, isCompanyLoading } = useGetCompanyInfo(companyId);

  if (isCompanyLoading) return <LoadingSkeleton />;
  if (!currentCompany) return null;

  const { company, deals } = currentCompany;

  const isCompanyFrozen = company?.company_status === CompanyStatus.Frozen;

  const handleFreezeCompany = async () => {
    try {
      const completeData = {
        ...company,
        company_status: isCompanyFrozen ? CompanyStatus.Invited : CompanyStatus.Frozen,
      };
      await dispatch(updateCompanyThunk({ id: company.id, updatedData: completeData }));

      await dispatch(getCompaniesThunk());
    } catch (error) {
      console.error('Error updating company:', error);
    }
    setFreezeCompanyModalOpen(false);
  };

  return (
    <>
      {company && !isCompanyLoading && (
        <>
          <Box padding="0 40px 100px 40px">
            <ScrapexSidebarTextH1>{t('accountManager.companyInfoDrawer.label')}</ScrapexSidebarTextH1>
            <List>
              <DataListItem title="Company Status">
                <CompanyStatusIndicator status={company.company_status} />
              </DataListItem>
              <DataListItem title="Company Name">{company.name}</DataListItem>
              <DataListItem title="Company Email">{company.email}</DataListItem>
              <DataListItem title="Invitation date">
                {formatDate(company.invitation_date)}
              </DataListItem>
              <DataListItem title="Registration date" hideDivider>
                {formatDate(company.invitation_date)}
              </DataListItem>
            </List>
            <ScrapexSidebarTextH1>{t('accountManager.companyInfoDrawer.contracts')} ({deals.length})</ScrapexSidebarTextH1>
            <List>
              {deals.length ? (
                deals.map((deal) => (
                  <DataListItem key={deal.contract_number} title="Contract No.">
                    <ScrapexSidebarMediumTextH2>{deal.contract_number}</ScrapexSidebarMediumTextH2>
                    <NavigateIconButton id={deal.id} />
                  </DataListItem>
                ))
              ) : (
                <Typography>{t('accountManager.companyInfoDrawer.noContracts')}</Typography>
              )}
            </List>
          </Box>

          <DrawerFooter justifyContent="space-between">
            <Button variant="contained" onClick={onClose}>
            {t('accountManager.companyInfoDrawer.close')}
            </Button>
            <Box>
              <Button
                variant="outlined"
                color="error"
                sx={{ marginRight: '16px' }}
                onClick={() => setFreezeCompanyModalOpen(true)}
              >
                {isCompanyFrozen ? 'UNFREEZE COMPANY' : 'FREEZE COMPANY'}
              </Button>
              <Button variant="outlined" onClick={onOpenEdit}>
              {t('accountManager.companyInfoDrawer.editCompany')}
              </Button>
            </Box>
          </DrawerFooter>
          <FreezeCompanyModal
            open={freezeCompanyModalOpen}
            onClose={() => setFreezeCompanyModalOpen(false)}
            onConfirm={handleFreezeCompany}
            company={company}
            isFrozen={isCompanyFrozen}
          />
        </>
      )}
    </>
  );
};
