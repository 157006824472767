import { Box, IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';

import { HedgingStatusIndicator } from '@common/components/display/hedging/status';
import { formatDate, formatTime, renderDateOrNone, renderValueOrNone } from '@app/utils';
import { TextWithSubtitle } from '@common/components/display/text';

import { CircleComponent } from 'src/hedgeAdmin/hedging/shared/table/circleComponent';
import { HedgeType } from 'src/account/contract/components/shipments/hedging/components/hedgeRequestDrawer/helpers';
import { ScrapexGrayText } from '@common/components';
import { ChatBubbleOutlineOutlined } from '@mui/icons-material';
import { IHedge } from '@app/types';
import { getUserRole } from 'src/hedgeAdmin/hedging/shared/utils';
import { dateSortComparator } from '@app/utils/sortComparators';
import { getDateValue } from '@app/utils/getDateValue';
import { TFunction } from 'i18next';

interface IGenerateHedgeRequestsColumns {
  onHandleCommentIconClick: (event: React.MouseEvent, value: IHedge) => void;
  t: TFunction;
}

export const generateHedgeHistoryColumns = ({
  onHandleCommentIconClick,
  t,
}: IGenerateHedgeRequestsColumns) => {
  const columns: GridColDef[] = [
    {
      field: 'hedge_status',
      headerName: t('account.contract.status') || 'Status',
      width: 160,

      renderCell: (params) => <HedgingStatusIndicator status={params.value} />,
    },
    {
      field: 'hedge_type',
      headerName: t('account.contract.type') || 'Type',
      width: 116,
      renderCell: (params) => params.value.toUpperCase(),
    },
    {
      field: 'role',
      headerName: '',
      width: 68,
      align: 'center',
      valueGetter: (params) => getUserRole(params.row.contract_number),
      renderCell: (params) => <CircleComponent contractNumber={params.row.contract_number} />,
    },
    {
      field: 'contract_number',
      headerName: t('account.contract.contractNo') || 'Contract No.',
      width: 168,
      align: 'left',
      renderCell: (params) => params.value,
    },
    {
      field: 'hedge_number',
      headerName: t('account.contract.hedgeNo') || 'Hedge No.',
      width: 168,
      align: 'left',
      renderCell: (params) => params.value,
    },
    {
      field: 'request_time',
      headerName: t('account.contract.dateOfCreation') || 'Date of creation',
      width: 140,
      valueGetter: (params) => {
        return getDateValue(params.value);
      },
      renderCell: (params) =>
        params.value ? (
          <TextWithSubtitle
            title={params.value.displayDate}
            subtitle={formatTime(params.row.request_time)}
          />
        ) : (
          <ScrapexGrayText>{t('account.contract.none') || 'None'}</ScrapexGrayText>
        ),
      sortComparator: (a, b) => {
        return dateSortComparator(a?.isoDate, b?.isoDate);
      },
    },
    {
      field: 'filled_time',
      headerName: t('account.contract.executionDate') || 'Execution date',
      width: 140,
      valueGetter: (params) => {
        return getDateValue(params.value);
      },
      renderCell: (params) =>
        params.value ? (
          <TextWithSubtitle
            title={params.value.displayDate}
            subtitle={formatTime(params.row.filled_time)}
          />
        ) : (
          <ScrapexGrayText>{t('account.contract.none') || 'None'}</ScrapexGrayText>
        ),
      sortComparator: (a, b) => {
        return dateSortComparator(a?.isoDate, b?.isoDate);
      },
    },
    {
      field: 'product',
      headerName: t('account.contract.productMetal') || 'Product/Metal',
      width: 148,
      renderCell: (params) => <TextWithSubtitle title={params.value} subtitle={params.row.metal} />,
    },
    {
      field: 'weight',
      headerName: t('account.contract.tonnageMt') || 'Tonnage, mt',
      width: 148,
      renderCell: (params) => {
        const shipmentCount = params.row.hedge_shipments.length;
        const shipmentText = shipmentCount === 1 ? 'shipment' : 'shipments';
        return (
          <TextWithSubtitle title={params.value} subtitle={`${shipmentCount}  ${shipmentText}`} />
        );
      },
    },

    {
      field: 'formula',
      headerName: t('account.contract.formula') || 'Formula',
      width: 148,
    },
    {
      field: 'quotation',
      headerName: t('account.contract.currentLmeRequest') || 'Current LME Request',
      width: 200,
      sortable: false,
      renderCell: (params) => {
        const { hedge_type, tp_exchange_level, olme_date } = params.row;
        switch (hedge_type) {
          case HedgeType.ACM:
            return renderValueOrNone(t, params.value);
          case HedgeType.TakeProfit:
            return (
              <span>
                {renderValueOrNone(t, tp_exchange_level)}
                {tp_exchange_level && <ScrapexGrayText> TP</ScrapexGrayText>}
              </span>
            );
          case HedgeType.OLME:
            return renderDateOrNone(t, olme_date);
          default:
            return null;
        }
      },
    },
    {
      field: 'fixed_exchange',
      headerName: t('account.contract.fixedLme') || 'Fixed LME',
      width: 140,
      renderCell: (params) => renderValueOrNone(t, params.value),
    },
    {
      field: 'message',
      headerName: t('account.contract.comments') || 'Comments',
      width: 140,
      renderCell: (params) => (
        <>
          {params.value ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={(event) => onHandleCommentIconClick(event, params.row)}
            >
              1
              <IconButton>
                <ChatBubbleOutlineOutlined fontSize="small" color="action" />
              </IconButton>
            </Box>
          ) : (
            <ScrapexGrayText>{t('account.contract.none') || 'None'}</ScrapexGrayText>
          )}
        </>
      ),
    },
    {
      field: 'limit_fixation_date',
      headerName: t('account.contract.limitFixationDate') || 'Limit Fixation Date',
      width: 140,
      valueGetter: (params) => {
        return params.value ? formatDate(params.value) : null;
      },
      renderCell: (params) => renderDateOrNone(t, params.row.limit_fixation_date),
      sortComparator: dateSortComparator,
    },
  ];

  return { columns };
};
