import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from '@app/store';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'src/account/reducer/hedging/slice';
import { DrawerGeneral } from '@common/components';
import { useOpenDrawer } from '@app/hooks';
import { HedgeRequestDrawer } from '../hedgeRequestDrawer';
import { HedgingStatus } from '@app/types';
import { ArrowForward } from '@mui/icons-material';

interface HedgeFooterProps {
  justifyContent?: string;
}

export const HedgeFooter = ({ justifyContent = 'flex-start' }: HedgeFooterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const hedge = useSelector((state: RootState) => state.account.hedging.currentHedge);
  const { isOpen, open, close } = useOpenDrawer();
  const { isSelectMode, selectedShipments } = useSelector(
    (state: RootState) => state.account.hedging,
  );

  const handleSelect = () => {
    dispatch(actions.enableSelect());
  };

  const handleCancel = () => {
    dispatch(actions.disableSelect());
  };

  return (
    <>
      <Box
        sx={{
          padding: '27px 60px',
          borderTop: '1px solid var(--other-300)',
          display: 'flex',
          justifyContent,
          backgroundColor: 'var(--white-color)',
        }}
      >
        {isSelectMode ? (
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Button variant="outlined" color="error" onClick={handleCancel}>
              {t('account.contract.cancel')}
            </Button>
            <Button
              variant="contained"
              disabled={!selectedShipments?.length}
              onClick={() => open()}
              endIcon={<ArrowForward />}
            >
              {t('account.contract.continue')}
            </Button>
            <Typography>{selectedShipments?.length || 0} {t('account.contract.shipmentsSelected')} </Typography>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Button variant="contained" onClick={handleSelect} endIcon={<ArrowForward />}>
              {t('account.contract.createGroupHedge')}
            </Button>
            <Typography>
            {t('account.contract.hedgingAvailableFrom')} <strong>8:00 to 16:00</strong> (London
              Time Zone)
            </Typography>
          </Box>
        )}
      </Box>
      <DrawerGeneral
        onClose={hedge?.hedge_status === HedgingStatus.Waiting ? undefined : close}
        title="Hedge Info"
        open={isOpen}
      >
        {isOpen && selectedShipments && (
          <HedgeRequestDrawer
            shipmentsIds={selectedShipments}
            onClose={hedge?.hedge_status === HedgingStatus.Waiting ? undefined : close}
          />
        )}
      </DrawerGeneral>
    </>
  );
};
