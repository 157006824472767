import { formatDate, renderDateOrNone, renderValueOrNone } from '@app/utils';
import { dateSortComparator } from '@app/utils/sortComparators';
import { ContractRole, ContractStatusIndicator, TextWithSubtitle } from '@common/components';
import { GridColDef } from '@mui/x-data-grid-premium';
import { TFunction } from 'i18next';

export const generateContractsColumns = (t: TFunction) => {
  const columns: GridColDef[] = [
    {
      field: 'deal_status',
      headerName: t('account.contract.status'),
      width: 120,

      renderCell: (params) => <ContractStatusIndicator status={params.value} />,
    },

    {
      field: 'customer_role',
      headerName: '',
      renderCell: (params) => <ContractRole role={params.value} />,
    },
    {
      field: 'contract_number',
      headerName: t('account.contract.contractNo'),
    },
    {
      field: 'counterparty',
      width: 160,
      headerName: t('account.contract.counterparty'),
    },
    {
      field: 'contract_date',
      headerName: t('account.contract.date'),
      width: 110,
      valueGetter: (params) => {
        return formatDate(params.value);
      },
      sortComparator: dateSortComparator,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'product',
      headerName: t('account.contract.productMetal'),
      renderCell: (params) => <TextWithSubtitle title={params.value} subtitle={params.row.metal} />,
    },
    {
      field: 'formula',
      width: 160,
      headerName: t('account.contract.price'),
    },
    {
      field: 'limit_fixation_date',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      headerName: t('account.contract.nearestLimitFixDate'),
      renderCell: (params) => renderDateOrNone(t, params.value),
    },
    {
      field: 'product_weight',
      width: 160,
      headerName: t('account.contract.contractWeightMt'),
      align: 'right',
    },
    {
      field: 'total_shipments',
      width: 160,
      headerName: t('account.contract.totalShipments'),
      align: 'right',
    },
    {
      field: 'hedged_shipments',
      width: 160,
      headerName: t('account.contract.hedgedShipments'),
      align: 'right',
      renderCell: (params) => renderValueOrNone(t, params.value),
    },
    {
      field: 'shipments_in_orders',
      width: 160,
      headerName: t('account.contract.shipmentsInOrders'),
      align: 'right',
      renderCell: (params) => renderValueOrNone(t, params.value),
    },
    {
      field: 'request_shipments',
      width: 200,
      headerName: t('account.contract.shipmentsInHedgeRequest'),
      align: 'right',
      renderCell: (params) => renderValueOrNone(t, params.value),
    },
  ];
  return { columns };
};
