import { AddOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';


export const AddButton = () => {
  const { t } = useTranslation();

  return (
  <Button disabled variant="outlined" color="warning" startIcon={<AddOutlined />}>
    {t('account.contract.ADD')}
  </Button>
)};
