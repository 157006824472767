import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import classes from './styles.module.scss';
import { ContractStatus } from '@app/types';

export const ContractStatusIndicator = ({ status }: { status: ContractStatus }) => {
  const { t } = useTranslation();
  
  const contractStatusMap: { [key in ContractStatus]: string } = {
    active: t('account.contract.active'),
    filled: t('account.contract.filled'),
    canceled: t('account.contract.canceled'),
  };

  return (
    <Box className={`${classes[status]} ${classes.status}`}>
      {contractStatusMap[status as ContractStatus]}
    </Box>
  );
};
