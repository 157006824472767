import React from 'react';
import { Box } from '@mui/material';
import { ScrapexGrayText, ShipmentStatusIndicator } from '@common/components';
import { IHedgeShipment } from '@app/types';
import { renderDateOrNoneInDrawer, renderValueOrNone } from '@app/utils';
import { useTranslation } from 'react-i18next';

interface ShipmentCardProps {
  shipment: IHedgeShipment;
}

export const ShipmentCard: React.FC<ShipmentCardProps> = ({ shipment }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ backgroundColor: '#F9FAFB', my: 1 }}>
      <Box display="flex" flexDirection="column" p="16px 40px" gap={2}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            {shipment.ordinal_number}

            <ScrapexGrayText>Ordinal No.</ScrapexGrayText>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            {renderValueOrNone(t, shipment.shipment_number)}
            <ScrapexGrayText>Shipment No.</ScrapexGrayText>
          </Box>
          <Box>
            <ShipmentStatusIndicator status={shipment.shipment_status} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box>
            <ScrapexGrayText>ETA: </ScrapexGrayText>
            {renderDateOrNoneInDrawer(t, shipment.eta)}
          </Box>
          <Box>
            <ScrapexGrayText>Limit Fixation Date: </ScrapexGrayText>
            {renderDateOrNoneInDrawer(t, shipment.limit_fixation_date)}
          </Box>
          <Box>
            <ScrapexGrayText>
              {shipment.final_weight ? 'Final Weight: ' : 'Estimated Weight: '}
            </ScrapexGrayText>
            {shipment.final_weight
              ? `${shipment.final_weight} mt`
              : `${shipment.estimated_weight} mt`}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
