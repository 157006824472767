import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { ScrapexColorEnum } from '../../common/colors';
import { ScrapexSubitle, ScrapexTitleH1 } from '../../common/components/display/text_componets';
import classes from './styles.module.scss';

export const HeaderEditCompany = () => {
  const { t } = useTranslation();
  const companyName = useSelector(
    (state: RootState) => state.auth.companyRegistration.mainInfoForm.name,
  );
  return (
    <Box className={classes.header}>
      <ScrapexTitleH1 color={ScrapexColorEnum.MAIN_TEXT_COLOR}>{companyName}</ScrapexTitleH1>
      <ScrapexSubitle color={ScrapexColorEnum.FILL_TEXT_COLOR}>{t('account.contract.editCompanyProfile')}</ScrapexSubitle>
    </Box>
  );
};
