import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import {
  formatDate,
  renderDateOrNone,
  renderDoneIconOrNone,
  renderEstimatedOrFinalWeight,
  renderHedgingDetails,
  renderQuantityOrNone,
  renderValueOrNone,
} from '@app/utils';
import {
  ScrapexBodyTextMedium,
  ScrapexGrayText,
  ShipmentStatusIndicator,
} from '@common/components';
import classes from '../styles.module.scss';
import { dateSortComparator, hedgeSortComparator } from '@app/utils/sortComparators';

export const generateShipmentColumns = () => {
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'ordinal_number',
      headerName: 'Ordinal No.',
      width: 96,
      headerClassName: classes.commonHeader,
    },
    {
      field: 'shipment_number',
      headerName: 'Shipment No.',
      width: 140,
      headerClassName: classes.commonHeader,
      renderCell: (params) => (
        <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '14px' }}>
          {renderValueOrNone(t, params.value)}
        </Typography>
      ),
    },
    {
      field: 'shipment_status',
      headerName: 'Status',
      width: 160,
      headerClassName: classes.commonHeader,
      renderCell: (params) => <ShipmentStatusIndicator status={params.value} />,
    },
    {
      field: 'claims',
      headerName: 'Claims',
      width: 116,
      headerClassName: classes.commonHeader,
      renderCell: (params) => renderValueOrNone(t, params.value),
    },
    {
      field: 'eta',
      headerName: 'ETA',
      width: 120,
      headerAlign: 'right',
      align: 'right',
      headerClassName: classes.commonHeader,
      valueGetter: (params) => {
        return params.value ? formatDate(params.value) : null;
      },
      renderCell: (params) => renderDateOrNone(t, params.value),
      sortComparator: dateSortComparator,
    },
    {
      field: 'final_weight',
      headerName: 'Final Weight, mt',
      width: 120,
      headerClassName: classes.commonHeader,
      cellClassName: classes.border,
      valueGetter: (params) => {
        return params.value ?? params.row.estimated_weight;
      },
      renderCell: (params) => {
        return renderEstimatedOrFinalWeight(params.row.final_weight, params.row.estimated_weight);
      },
    },
    {
      field: 'connaissement',
      headerName: 'BL/Telex/SWB',
      width: 148,
      headerClassName: classes.commonHeader,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => renderDoneIconOrNone(t, params.value),
    },
    {
      field: 'quality_certificate',
      headerName: 'Quality Certificate',
      width: 148,
      headerClassName: classes.commonHeader,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => renderDoneIconOrNone(t, params.value),
    },
    {
      field: 'packing_list',
      headerName: 'Packing List',
      width: 148,
      headerClassName: classes.commonHeader,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => renderDoneIconOrNone(t, params.value),
    },
    {
      field: 'insurance_certificate',
      headerName: 'Insurance Certificate',
      width: 148,
      headerClassName: classes.commonHeader,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => renderDoneIconOrNone(t, params.value),
    },
    {
      field: 'survey_report',
      headerName: 'Survey Report',
      width: 148,
      headerClassName: classes.commonHeader,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => renderDoneIconOrNone(t, params.value),
    },
    {
      field: 'other_files',
      headerName: 'Other',
      width: 148,
      headerClassName: classes.commonHeader,
      headerAlign: 'center',
      cellClassName: classes.border,
      align: 'center',
      sortable: false,
      renderCell: (params) => renderQuantityOrNone(t, params.value),
    },
    {
      field: 'seller',
      headerName: 'Seller',
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" flexDirection="column">
          <ScrapexBodyTextMedium>{params.row.seller_name}</ScrapexBodyTextMedium>
          <ScrapexGrayText>{params.row.seller_email}</ScrapexGrayText>
        </Box>
      ),
      headerClassName: classes.sellerHeader,
    },
    {
      field: 'seller_hedge',
      headerName: 'Hedging',
      width: 200,
      headerClassName: classes.sellerHeader,
      valueGetter: (params) => params?.row?.seller_hedge?.hedge_number,
      renderCell: (params) => (
        <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '14px' }}>
          {renderHedgingDetails(t, params?.row?.seller_hedge)}
        </Typography>
      ),
      sortComparator: hedgeSortComparator,
    },
    {
      field: 'seller_limit_fixation_date',
      headerName: 'Limit Fix.Date',
      width: 140,
      headerClassName: classes.sellerHeader,
      valueGetter: (params) => {
        return params.value ? formatDate(params.value) : null;
      },
      renderCell: (params) => renderDateOrNone(t, params.value),
      sortComparator: dateSortComparator,
    },
    {
      field: 'seller_shipment_value',
      headerName: 'Shipment Value, USD',
      width: 180,
      renderCell: (params) => renderValueOrNone(t, params.value),
      headerClassName: classes.sellerHeader,
    },
    {
      field: 'seller_invoices',
      headerName: 'Invoices',
      width: 110,
      headerClassName: classes.sellerHeader,
      sortable: false,
      renderCell: (params) => renderQuantityOrNone(t, params.value),
    },
    {
      field: 'seller_other_files',
      headerName: 'Other',
      width: 110,
      headerClassName: classes.sellerHeader,
      cellClassName: classes.border,
      sortable: false,
      renderCell: (params) => renderQuantityOrNone(t, params.value),
    },
    {
      field: 'buyer',
      headerName: 'Buyer',
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" flexDirection="column">
          <ScrapexBodyTextMedium>{params.row.buyer_name}</ScrapexBodyTextMedium>
          <ScrapexGrayText>{params.row.buyer_email}</ScrapexGrayText>
        </Box>
      ),
      headerClassName: classes.buyerHeader,
    },
    {
      field: 'buyer_hedge',
      headerName: 'Hedging',
      width: 200,
      headerClassName: classes.buyerHeader,
      valueGetter: (params) => params?.row?.buyer_hedge?.hedge_number,
      renderCell: (params) => (
        <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '14px' }}>
          {renderHedgingDetails(t, params?.row?.buyer_hedge)}
        </Typography>
      ),
      sortComparator: hedgeSortComparator,
    },
    {
      field: 'buyer_limit_fixation_date',
      headerName: 'Limit Fix.Date',
      width: 140,
      headerClassName: classes.buyerHeader,
      valueGetter: (params) => {
        return params.value ? formatDate(params.value) : null;
      },
      renderCell: (params) => renderDateOrNone(t, params.value),
      sortComparator: dateSortComparator,
    },
    {
      field: 'buyer_shipment_value',
      headerName: 'Shipment Value, USD',
      width: 180,
      renderCell: (params) => renderValueOrNone(t, params.value),
      headerClassName: classes.buyerHeader,
    },
    {
      field: 'buyer_invoices',
      headerName: 'Invoices',
      width: 110,
      headerClassName: classes.buyerHeader,
      sortable: false,
      renderCell: (params) => renderQuantityOrNone(t, params.value),
    },
    {
      field: 'buyer_other_files',
      headerName: 'Other',
      width: 110,
      headerClassName: classes.buyerHeader,
      sortable: false,
      renderCell: (params) => renderQuantityOrNone(t, params.value),
    },
  ];

  return { columns };
};
