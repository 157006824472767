import { useTranslation } from 'react-i18next';
import { ISocketEventData } from '@app/types/api';
import { formatTimeWithoutGMT } from '@app/utils';
import { ScrapexGrayText } from '@common/components';
import { Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';

export const showNotification = (data: any) => {
  return toast(<CustomNotification {...data} />, { hideProgressBar: true });
};

const CustomNotification = (data: ISocketEventData) => {
  const { t } = useTranslation();

  const { value, number, content, created_at: createdAt } = data;
  const time = formatTimeWithoutGMT(createdAt);
  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start">
      <Box display="flex" gap={2} alignItems="center" mb={2}>
        <Typography sx={{ color: 'blue' }}>
          {value} {t('account.contract.no')} {number}
        </Typography>
        <ScrapexGrayText>
          {t('account.contract.now')}, {time}
        </ScrapexGrayText>
      </Box>
      <Typography>{content}</Typography>
    </Box>
  );
};
