import { useTranslation } from 'react-i18next';
import {
  DataListItem,
  DrawerFooter,
  LoadingSkeleton,
  ScrapexGrayText,
  ScrapexSidebarTextH1,
} from '@common/components';
import { Box, Button, Typography } from '@mui/material';
import { CopyIcon } from '../copyIcon';

import { HedgeRequestDrawerInfo } from '../hedgeRequestDrawerInfo';
import { useGetCurrentHedge } from 'src/hedgeAdmin/hedging/shared/hooks/useGetCurrentHedge';
import { HedgingStatus } from '@app/types';
import { ArrowForward } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@app/store';
import {
  placeOrderThunk,
  setOrderFixedExchangeThunk,
  setRejectWithMessageThunk,
  setRejectOrderThunk,
} from 'src/hedgeAdmin/reducer/hedging/thunk';
import { formatDate } from '@app/utils';
import { HedgeType } from '@app/types/api';
import { useState } from 'react';
import { HedgeRejectModal } from '../hedgeRejectModal';
import { DotDecimalInput } from '@common/components/display/hedging/input';

interface IHedgeRequestDrawerProps {
  hedgeId: string;
  onClose: () => void;
}

export const HedgeRequestDrawerOLME = ({ hedgeId, onClose }: IHedgeRequestDrawerProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [hedgeRejectModalOpen, setHedgeRejectModalOpen] = useState<boolean>(false);
  const { hedge, loading, error } = useGetCurrentHedge(hedgeId);

  const [fixedExchange, setFixedExchange] = useState<string>('');

  const handleChangeFixedExchange = (value: string) => {
    setFixedExchange(value);
  };

  const placeOrder = () => {
    dispatch(placeOrderThunk(hedgeId));
    onClose();
  };

  const handleRejectOrder = () => {
    dispatch(setRejectOrderThunk(hedgeId));
    onClose();
  };

  const handleRejectWithMessage = (message: string = '') => {
    dispatch(setRejectWithMessageThunk({ hedgeId, message }));
    onClose();
  };

  const sendFixedExchange = () => {
    const fixedExchangeValue = parseFloat(fixedExchange);

    dispatch(setOrderFixedExchangeThunk({ fixed_exchange: fixedExchangeValue, hedgeId }));
    onClose();
  };

  const { t } = useTranslation();

  const renderFooterContent = () => {
    switch (hedge?.hedge_status) {
      case HedgingStatus.WaitingOLME:
        return (
          <Box display="flex" gap={2} ml="auto">
            <Button variant="outlined" color="error" onClick={() => setHedgeRejectModalOpen(true)}>
            {t('hedge.rejectOrder')}
            </Button>
            <Button variant="contained" onClick={placeOrder} endIcon={<ArrowForward />}>
            {t('hedge.placeOrder')}
            </Button>
          </Box>
        );
      case HedgingStatus.AtWorkOLME:
        return (
          <Box display="flex" gap={2} ml="auto">
            <Button variant="contained" color="error" onClick={() => setHedgeRejectModalOpen(true)}>
            {t('hedge.cancelOrder')}
            </Button>
            <Button
              variant="contained"
              onClick={sendFixedExchange}
              endIcon={<ArrowForward />}
              disabled={parseFloat(fixedExchange) <= 0 || fixedExchange === ''}
            >
              {t('hedge.sendFixedLme')}
            </Button>
          </Box>
        );
      case HedgingStatus.UserRejectedRequestOLME:
        return (
          <Box display="flex" width="100%" justifyContent="space-between">
            <Button variant="contained" color="error" onClick={placeOrder}>
            {t('hedge.declineCancelingRequest')}
            </Button>
            <Button variant="contained" onClick={handleRejectOrder}>
            {t('hedge.acceptCancelingRequest')}
            </Button>
          </Box>
        );
      case HedgingStatus.UserRejectedOrderOLME:
        return (
          <Box display="flex" width="100%" justifyContent="space-between">
            <Button variant="contained" color="error" onClick={placeOrder}>
            {t('hedge.declineCancelingOrder')}
            </Button>
            <Button variant="contained" onClick={handleRejectOrder}>
            {t('hedge.acceptCancelingOrder')}
            </Button>
          </Box>
        );

      default:
        return (
          <Button variant="contained" onClick={onClose}>
            {t('hedge.close')}
          </Button>
        );
    }
  };

  const brokerMessage = `Lorem ipsum`;

  return (
    <>
      {loading && <LoadingSkeleton />}
      {error && <p>Error: {error.message}</p>}
      {!loading && !error && hedge && (
        <>
          <Box padding="34px 40px 100px 40px">
            <ScrapexSidebarTextH1>{t('hedge.hedgeRequest')}</ScrapexSidebarTextH1>
            <ScrapexGrayText>{t('hedge.hedgeNumber')}</ScrapexGrayText>
            {hedge.hedge_number}
            <HedgeRequestDrawerInfo hedge={hedge} />
            <ScrapexSidebarTextH1>{t('hedge.order')}</ScrapexSidebarTextH1>
            <DataListItem title={t('hedge.pureMetalMt')}>{hedge.pure_metal}</DataListItem>
            <DataListItem title={t('hedge.orderTypeMt')}>{t('hedge.officialLme')}</DataListItem>
            {/* <DataListItem title="Take Profit Exchange Level, USD/mt">
              {hedge.tp_exchange_level}
            </DataListItem> */}
            <DataListItem title={t('hedge.date')}>{formatDate(hedge?.olme_date)}</DataListItem>
            <DataListItem title={t('hedge.brokersMessage')}>
              <Box display="flex" alignItems="center" justifyContent="end">
                <Typography>{brokerMessage}</Typography>
                <CopyIcon textToCopy={brokerMessage} />
              </Box>
            </DataListItem>
            {hedge.hedge_status === HedgingStatus.AtWorkOLME && (
              <DataListItem title={t('hedge.fixedLmeUsdMt')}>
                <DotDecimalInput
                  value={fixedExchange}
                  onChange={handleChangeFixedExchange}
                  placeholder="Enter Fixed LME"
                />
              </DataListItem>
            )}
            {hedge.hedge_status === HedgingStatus.FilledOLME && (
              <>
                <DataListItem title={t('hedge.fixedLmeUsdMt')}>{hedge.fixed_exchange}</DataListItem>
                <DataListItem title={t('hedge.finalUnitPriceOlme')}>
                  {hedge.final_product_price}
                </DataListItem>
              </>
            )}
          </Box>
          <HedgeRejectModal
            open={hedgeRejectModalOpen}
            onClose={() => setHedgeRejectModalOpen(false)}
            onConfirm={handleRejectWithMessage}
            hendgeNumber={hedge.hedge_number}
            hedgeType={hedge.hedge_type as HedgeType}
          />
          <DrawerFooter justifyContent="space-between">{renderFooterContent()}</DrawerFooter>
        </>
      )}
    </>
  );
};
