import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.scss';
import { Add } from '@mui/icons-material';

export const FAQ = () => {
  const [openQuestion, setOpenQuestion] = useState<number | null>(null);
  const { t } = useTranslation();

  const toggleQuestion = (index: number) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const faqs = [
    {
      question: t('landing.questions.faqs1.question'),
      answer: t('landing.questions.faqs1.answer'),
    },
    {
      question: t('landing.questions.faqs2.question'),
      answer: t('landing.questions.faqs2.answer'),
    }, {
      question: t('landing.questions.faqs3.question'),
      answer: t('landing.questions.faqs3.answer'),
    },
  ];

  return (
    <section className={classes.faq} id="faq">
      <div className={classes.title}>
        {' '}
        <h2>{t('landing.questions.label')}</h2>
      </div>

      <div className={classes.list}>
        {faqs.map((faq, index) => (
          <div key={index} className={classes.item}>
            <div className={classes.question} onClick={() => toggleQuestion(index)}>
              <h3>{faq.question}</h3>
              <span className={`${classes.icon} ${openQuestion === index ? classes.open : ''}`}>
                <Add fontSize="large" color="action" />
              </span>
            </div>
            <div
              className={`${classes.answerWrapper} ${openQuestion === index ? classes.open : ''}`}
            >
              <p className={classes.answer}>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
