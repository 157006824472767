import { useTranslation } from 'react-i18next';
import { ShipmentStatusIndicator, TextWithSubtitle } from '@common/components';
import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';

import { LimitFixationDate } from '../../limitFixationDate';
import {
  renderDateOrNone,
  renderEstimatedOrFinalWeight,
  renderHedgingDetails,
  renderValueOrNone,
} from '@app/utils';

export const generateHedgeDetailColumns = (
  onHandleUpdateLFD?: (id: string, date: string, field: string) => void,
  isItHistory?: boolean,
) => {
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'shipment_number',
      headerName: 'Shipment No.',
      width: 200,
      renderCell: (params) => renderValueOrNone(t, params.value),
    },
    {
      field: 'ordinal_number',
      headerName: 'Ord.No',
      align: 'right',
      headerAlign: 'right',
      width: 80,
    },
    {
      field: 'shipment_status',
      headerName: 'Ship. Status',
      type: 'number',
      width: 118,
      renderCell: (params) => <ShipmentStatusIndicator status={params.value} />,
    },
    {
      field: 'final_weight',
      headerName: 'Final Weight, mt',
      type: 'number',
      width: 120,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) =>
        renderEstimatedOrFinalWeight(params.row.final_weight, params.row.estimated_weight),
    },
    {
      field: 'pure_metal',
      headerName: 'Pure Metal, mt',
      type: 'number',
      width: 120,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'limit_fixation_date',
      headerName: 'Limit Fixation Date',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      width: 175,
      renderCell: (params) => {
        return isItHistory ? (
          renderDateOrNone(t, params.row?.limit_fixation_date)
        ) : (
          <LimitFixationDate
            initialDate={params.row?.limit_fixation_date}
            onHandleUpdateShipment={(date) => {
              onHandleUpdateLFD?.(params.row.id, date, `${params.row.role}_limit_fixation_date`);
            }}
          />
        );
      },
    },
    {
      field: 'counterparty_name',
      headerName: 'Counterparty Name',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      width: 200,
      renderCell: (params) => (
        <TextWithSubtitle title={params.value} subtitle={params?.row.counterparty_role} />
      ),
    },
    {
      field: 'counterparty_hedge',
      headerName: 'Hedging Number',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      width: 220,
      renderCell: (params) => (
        <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '14px' }}>
          {renderHedgingDetails(t, params.value)}
        </Typography>
      ),
    },
    {
      field: 'counterparty_pure_metal',
      headerName: 'Pure Metal, mt',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      width: 120,
      renderCell: (params) => renderValueOrNone(t, params.row?.counterparty_hedge?.pure_metal),
    },
    {
      field: 'counterparty_lfd',
      headerName: 'Limit Fixation Date',
      type: 'number',
      width: 176,
      renderCell: (params) => {
        return isItHistory ? (
          renderDateOrNone(t, params.row?.counterparty_limit_fixation_date)
        ) : (
          <LimitFixationDate
            initialDate={params.row?.counterparty_limit_fixation_date}
            onHandleUpdateShipment={(date) => {
              onHandleUpdateLFD?.(
                params.row.id,
                date,
                `${params.row.counterparty_role}_limit_fixation_date`,
              );
            }}
          />
        );
      },
    },
  ];
  return { columns };
};
