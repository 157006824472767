import { TFunction } from 'i18next';

export const getContractColumns = (t: TFunction) => [
  t('account.contract.role'),
  t('account.contract.product'),
  t('account.contract.price'),
  t('account.contract.contractWeightMt'),
  t('account.contract.shipments'),
  t('account.contract.hedgedShipments'),
  t('account.contract.availableForHedging'),
  t('account.contract.completedShipments'),
];
