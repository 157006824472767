import { Box, ButtonGroup, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OpenInNewOutlined, DownloadingOutlined } from '@mui/icons-material';
import { ScrapexGrayText, ScrapexSidebarMediumTextH2 } from '@common/components';
import { useGetShipmentFiles } from '@app/hooks/useGetShipmentFiles';
import { FilesModal } from '../filesModal';
import { UserPhotoModal } from '../photoModal';

interface FilesFieldProps {
  fileIds?: string[];
  dealId?: string;
  folder: string;
  title: string;
  type: string;
}

export const FilesField: React.FC<FilesFieldProps> = ({
  fileIds = [],
  dealId = '',
  folder,
  title,
  type,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const { link } = useGetShipmentFiles(dealId, folder);

  return (
    <>
      {fileIds && fileIds.length > 0 ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: 1 }}>
          <ScrapexSidebarMediumTextH2>{fileIds.length}</ScrapexSidebarMediumTextH2>
          <ButtonGroup>
            <IconButton onClick={() => setModalOpen(true)}>
              <OpenInNewOutlined fontSize="small" />
            </IconButton>
            <IconButton href={link}>
              <DownloadingOutlined fontSize="small" />
            </IconButton>
          </ButtonGroup>
        </Box>
      ) : (
        <ScrapexGrayText>{t('account.contract.none')}</ScrapexGrayText>
      )}
      {type === 'files' && (
        <FilesModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          dealId={dealId}
          folder={folder}
          title={title}
          fileIds={fileIds}
        />
      )}

      {type === 'photo' && (
        <UserPhotoModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          dealId={dealId}
          folder={folder}
          title={title}
          fileIds={fileIds}
        />
      )}
    </>
  );
};
