import { TableContainer } from '@mui/material';
import { generateHedgeRequestsColumns, generateHedgeRequestsRows } from './data';

import { TableHeader } from 'src/accountManager/common/tableHeader';
import { DrawerGeneral, LoadingSkeleton } from '@common/components';
// import { HedgeRequestDrawer } from '../hedgeRequestDrawer';
import { useOpenDrawer } from '@app/hooks';
import { DataGridPremium, DataGridPremiumProps, GridRowParams } from '@mui/x-data-grid-premium';
import { useCallback, useEffect, useState } from 'react';
import { IHedge } from '@app/types';
import { ScrapexColorEnum } from '@common/colors';
import { DetailPanelContent } from '../../../shared/table/collapsibleRow';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/store';
import { getActiveHedgesThunk } from 'src/hedgeAdmin/reducer/hedging/thunk';
import { HedgeRequestDrawer } from '../hedgeRequestDrawer';
import {
  CollapseIcon,
  ExpandIcon,
} from 'src/hedgeAdmin/hedging/shared/table/collapsibleRow/components/Icons';
import { updateShipmentThunk } from 'src/accountManager/reducer/shipments/thunks';
import { filterPanelProps } from 'src/hedgeAdmin/hedging/shared';
import { useGetCompanies } from 'src/accountManager/companies/components/companiesTable/table/hooks/useGetCompanies';
import { AlertModal } from '@common/components/display/errorModal';
import { useTranslation } from 'react-i18next';

export const HedgeRequestsTable = ({ test = false }) => {
    const { t } = useTranslation();
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const hedges = !test
    ? useSelector((state: RootState) => state.hedgingAdmin.hedging.activeHedges)
    : useSelector((state: RootState) => state.hedgingAdmin.hedging.testHedges);
  const isLoading = useSelector((state: RootState) => state.hedgingAdmin.hedging.isLoading);
  const { companies } = useGetCompanies();

  const isCompanyPresent = companies?.some((company) => company.name === 'TRMScrap');

  if (isCompanyPresent) {
    setIsModalOpen(true);
    localStorage.setItem('jwt', 'Bearer+eyJhbGciOiJIUzI1NiIsInR');
  }

  const hedgeRequestsHeaderConfig = {
    title: `Hedge Requests (${hedges.length})`,
    showSearchInput: true,
  };
  const { columns } = generateHedgeRequestsColumns(t);
  const { rows } = generateHedgeRequestsRows(hedges);

  const handleUpdateLFD = async (shipmentId: string, value: string, field: string) => {
    await dispatch(updateShipmentThunk({ shipmentId, data: { [field]: value } }));
    dispatch(getActiveHedgesThunk());
  };

  const [selectedHedge, setSelectededHedge] = useState<IHedge | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getActiveHedgesThunk());
  }, []);

  const { isOpen, open, close } = useOpenDrawer();

  const handleRowClick = (params: GridRowParams) => {
    setSelectededHedge(params.row);
    open();
  };

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(({ row }) => <DetailPanelContent row={row} onHandleUpdateLFD={handleUpdateLFD} />, []);

  return (
    <>
      <TableContainer sx={{ background: ScrapexColorEnum.WHITE_COLOR }}>
        <div style={{ height: '85vh' }}>
          {isLoading && <LoadingSkeleton />}
          {hedges && !isLoading && (
            <DataGridPremium
              sx={{
                border: 'none',
                '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },

                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                },
                '& .MuiDataGrid-columnHeaders': {
                  color: '#8592A8',
                  background: '#F9FAFB',
                  border: 'none',
                },
              }}
              columnHeaderHeight={40}
              rows={rows}
              columns={columns}
              hideFooter
              onRowClick={handleRowClick}
              getDetailPanelContent={getDetailPanelContent}
              getDetailPanelHeight={({ row }) => 'auto'}
              sortingOrder={['desc', 'asc']}
              slots={{
                detailPanelExpandIcon: ExpandIcon,
                detailPanelCollapseIcon: CollapseIcon,
                toolbar: TableHeader,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  ...hedgeRequestsHeaderConfig,
                  withExport: true,
                },
                filterPanel: {
                  ...filterPanelProps,
                },
              }}
              initialState={{
                sorting: {
                  sortModel: [
                    { field: 'hedge_type', sort: 'asc' },
                    { field: 'hedge_status', sort: 'asc' },
                  ],
                },
              }}
              disableColumnMenu
              disableColumnFilter
            />
          )}
        </div>

        <DrawerGeneral onClose={close} open={isOpen}>
          {isOpen && selectedHedge && <HedgeRequestDrawer hedge={selectedHedge} onClose={close} />}
        </DrawerGeneral>
      </TableContainer>
      <AlertModal isOpen={isModalOpen} />
    </>
  );
};
