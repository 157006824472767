import { useTranslation } from 'react-i18next';
import { HedgingStatus } from '@app/types';
import {
  DataListItem,
  DrawerFooter,
  LoadingSkeleton,
  ScrapexGrayText,
  ScrapexSidebarTextH1,
} from '@common/components';
import { Box, Button, Typography } from '@mui/material';
import { CopyIcon } from '../copyIcon';
import { ArrowForward } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@app/store';
import {
  placeOrderThunk,
  setOrderFixedExchangeThunk,
  setRejectOrderThunk,
  setRejectWithMessageThunk,
} from 'src/hedgeAdmin/reducer/hedging/thunk';
import { HedgeRejectModal } from '../hedgeRejectModal';
import { HedgeRequestDrawerInfo } from '../hedgeRequestDrawerInfo';
import { useState } from 'react';
import { HedgeType } from '../../helpers';
import { EditOrderModal } from '../editOrderModal';
import { useGetCurrentHedge } from 'src/hedgeAdmin/hedging/shared/hooks/useGetCurrentHedge';
import { DotDecimalInput } from '@common/components/display/hedging/input';
import { renderValueOrNoneInDrawer } from '@app/utils';

interface IHedgeRequestDrawerProps {
  hedgeId: string;
  onClose: () => void;
}

export const HedgeRequestDrawerTP = ({ hedgeId, onClose }: IHedgeRequestDrawerProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();
  const { hedge, loading, error } = useGetCurrentHedge(hedgeId);
  const [hedgeRejectModalOpen, setHedgeRejectModalOpen] = useState<boolean>(false);
  const [editOrderModalOpen, setEditOrderModalOpen] = useState<boolean>(false);

  const [fixedExchange, setFixedExchange] = useState<string>('');

  const handleChangeFixedExchange = (value: string) => {
    setFixedExchange(value);
  };

  const sendFixedExchange = () => {
    const fixedExchangeValue = parseFloat(fixedExchange);

    dispatch(setOrderFixedExchangeThunk({ fixed_exchange: fixedExchangeValue, hedgeId }));
    onClose();
  };

  const placeOrder = () => {
    dispatch(placeOrderThunk(hedgeId));
    onClose();
  };

  const handleConfirmReject = (message: string = '') => {
    handleRejectOrderWithMessage(message);
    setHedgeRejectModalOpen(false);
  };

  const handleRejectOrder = () => {
    dispatch(setRejectOrderThunk(hedgeId));
    onClose();
  };

  const handleRejectOrderWithMessage = (message: string = '') => {
    dispatch(setRejectWithMessageThunk({ hedgeId, message }));
    onClose();
  };

  const renderFooterContent = () => {
    switch (hedge?.hedge_status) {
      case HedgingStatus.WaitingTP:
        return (
          <Box display="flex" gap={2} ml="auto">
            <Button variant="outlined" color="error" onClick={() => setHedgeRejectModalOpen(true)}>
            {t('hedge.rejectOrder')}
            </Button>
            <Button variant="contained" onClick={placeOrder} endIcon={<ArrowForward />}>
            {t('hedge.placeOrder')}
            </Button>
          </Box>
        );
      case HedgingStatus.AtWork:
        return (
          <Box display="flex" gap={2} ml="auto">
            <Button variant="outlined" color="warning" onClick={() => setEditOrderModalOpen(true)}>
            {t('hedge.editOrder')}
            </Button>
            <Button
              variant="contained"
              endIcon={<ArrowForward />}
              onClick={sendFixedExchange}
              disabled={parseFloat(fixedExchange) <= 0 || fixedExchange === ''}
            >
              {t('hedge.sendFixedLme')}
            </Button>
          </Box>
        );
      case HedgingStatus.UserRejectedRequest:
        return (
          <Box display="flex" width="100%" justifyContent="space-between">
            <Button variant="contained" color="error" onClick={placeOrder}>
            {t('hedge.declineCancelingRequest')}
            </Button>
            <Button variant="contained" onClick={handleRejectOrder}>
            {t('hedge.acceptCancelingRequest')}
            </Button>
          </Box>
        );
      case HedgingStatus.UserRejectedOrder:
        return (
          <Box display="flex" width="100%" justifyContent="space-between">
            <Button variant="contained" color="error" onClick={placeOrder}>
            {t('hedge.declineCancelingOrder')}
            </Button>
            <Button variant="contained" onClick={() => handleRejectOrder()}>
            {t('hedge.acceptCancelingOrder')}
            </Button>
          </Box>
        );
      default:
        return (
          <Button variant="contained" onClick={onClose}>
            {t('hedge.close')}
          </Button>
        );
    }
  };

  const brokerMessage = `Lorem ipsum`;

  return (
    <>
      {loading && <LoadingSkeleton />}
      {error && <p>{t('hedge.error')} {error.message}</p>}
      {!loading && !error && hedge && (
        <>
          <Box padding="34px 40px 100px 40px">
            <ScrapexSidebarTextH1>{t('hedge.hedgeRequest')}</ScrapexSidebarTextH1>
            <ScrapexGrayText>{t('hedge.hedgeNumber')}</ScrapexGrayText>
            {hedge.hedge_number}
            <HedgeRequestDrawerInfo hedge={hedge} />
            <ScrapexSidebarTextH1>{t('hedge.order')}</ScrapexSidebarTextH1>
            <DataListItem title={t('hedge.pureMetalMt')}>{hedge.pure_metal}</DataListItem>
            <DataListItem title={t('hedge.orderTypeMt')}>{t('hedge.takeProfit')} (TP)</DataListItem>
            <DataListItem title={t('hedge.takeProfitExchangeLevelUsdMt')}>
              {renderValueOrNoneInDrawer(hedge.tp_exchange_level)}
            </DataListItem>
            <DataListItem title={t('hedge.date')}>GTC</DataListItem>
            <DataListItem title={t('hedge.brokersMessage')}>
              <Box display="flex" alignItems="center" justifyContent="end">
                <Typography>{brokerMessage}</Typography>
                <CopyIcon textToCopy={brokerMessage} />
              </Box>
            </DataListItem>
            {hedge.hedge_status === HedgingStatus.AtWork && (
              <DataListItem title={t('hedge.fixedLmeUsdMt')}>
                <DotDecimalInput
                  value={fixedExchange}
                  onChange={handleChangeFixedExchange}
                  placeholder={t('hedge.enterFixedLme')}
                />
              </DataListItem>
            )}
            {hedge.hedge_status === HedgingStatus.FilledTP && (
              <>
                <DataListItem title={t('hedge.fixedLmeUsdMt')}>{hedge.fixed_exchange}</DataListItem>
                <DataListItem title={t('hedge.finalUnitPriceTpUsdMt')}>
                  {hedge.final_product_price}
                </DataListItem>
              </>
            )}
          </Box>
          <DrawerFooter justifyContent="space-between">{renderFooterContent()}</DrawerFooter>
          <HedgeRejectModal
            open={hedgeRejectModalOpen}
            onClose={() => setHedgeRejectModalOpen(false)}
            onConfirm={handleConfirmReject}
            hendgeNumber={hedge.hedge_number}
            hedgeType={hedge.hedge_type as HedgeType}
          />
          <EditOrderModal
            open={editOrderModalOpen}
            onClose={() => setEditOrderModalOpen(false)}
            onConfirm={(message) => handleRejectOrderWithMessage(message)}
          />
        </>
      )}
    </>
  );
};
