import { useTranslation } from 'react-i18next';
import classes from './styles.module.scss';

export const Contact = () => {
    const { t } = useTranslation();
  
  return (
    <section id="contact" className={`${classes.contact} `}>
      <main>
        <div className={classes.title}>
          <h2>{t('landing.contacts.label')}</h2>
        </div>
        <div className={classes.wrapper}>
          <div className={classes.info}>
            <p className={classes.phone}>{t('landing.contacts.tel')}</p>
            <p className={classes.email}>{t('landing.contacts.mail')}</p>
          </div>
          <div>
            {' '}
            <p className={classes.address}>
              {t('landing.contacts.address1')}
              <br />
              {t('landing.contacts.address2')}
              <br />
              {t('landing.contacts.address3')}
              <br />
              {t('landing.contacts.address4')}
              <br />
              {t('landing.contacts.address5')}
            </p>
          </div>
          {/* <div className={classes.form}>
            <form>
              <input type="email" placeholder="Your Email" required />
              <input type="text" placeholder="Your Full Name" required />
              <textarea placeholder="Your comments" required></textarea>
              <button type="submit">Send</button>
            </form>
          </div> */}
        </div>
      </main>
    </section>
  );
};
