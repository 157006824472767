import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { MobileLayout } from '@common/components/layout/mobile';
import { ContractCard } from './components/contractCard';
import { useContracts } from '../../hooks/useContracts';
import classes from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { IContractUser } from '@app/types/contracts';
import { useState, useMemo } from 'react';
import { Loader } from '@common/components';

export const ContractsMobile = () => {
  const { t } = useTranslation();
  const { contracts, loading } = useContracts();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const filteredContracts = useMemo(() => {
    if (!searchQuery.trim()) return contracts;

    const query = searchQuery.toLowerCase().trim();
    return contracts?.filter((contract) => contract.contract_number?.toLowerCase().includes(query));
  }, [contracts, searchQuery]);

  const handleContractClick = (contract: IContractUser) => {
    navigate(`/contracts/${contract.id}`);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const renderContent = () => {
    if (loading) {
      return <Loader />;
    }

    if (!filteredContracts?.length) {
      return (
        <Typography sx={{ p: 2, textAlign: 'center', color: 'text.secondary' }}>
          {searchQuery ? t('account.contract.noContractsFound') : t('account.contract.noContractsAvailable')}
        </Typography>
      );
    }

    return filteredContracts.map((contract) => (
      <ContractCard key={contract.id} contract={contract} onClick={handleContractClick} />
    ));
  };

  return (
    <MobileLayout onSearch={handleSearch}>
      <Box sx={{ px: 2, pt: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
        {t('account.contract.myContracts')}
        </Typography>
      </Box>

      <Box
        sx={{
          flex: 1,
          backgroundColor: 'var(--other-50)',
          minHeight: 0,
        }}
      >
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>{renderContent()}</Box>
      </Box>
    </MobileLayout>
  );
};
