import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { HedgingStatus, IHedge } from '@app/types';
import classes from '../../styles.module.scss';
import { formatDate, formatTime } from '@app/utils';
import { ActionTimer } from '../actionTimer';
import { CountdownTimer } from '@common/components';

interface ActionButtonsProps {
  isACMTab: boolean;
  hedge: IHedge | null;
  onCancel: () => void;
  onRequestQuote: () => void;
  onConfirm: () => void;
  onRejectQuotation: () => void;
  onRejectOrder: () => void;
  disabled?: boolean;
}

export const ActionButtons = ({
  isACMTab,
  hedge,
  onCancel,
  onRequestQuote,
  onConfirm,
  onRejectQuotation,
  onRejectOrder,
  disabled,
}: ActionButtonsProps) => {

  const { t } = useTranslation();
  
  switch (hedge?.hedge_status) {
    case HedgingStatus.None:
      return (
        <Box className={classes.actions}>
          {isACMTab ? (
            <Button variant="contained" fullWidth onClick={onRequestQuote}>
              {t('account.contract.requestAquote')}
            </Button>
          ) : (
            <Button variant="contained" fullWidth onClick={onRequestQuote} disabled={disabled}>
              {t('account.contract.placeAnOrder')}
            </Button>
          )}
          <Button variant="outlined" color="error" fullWidth onClick={onCancel} sx={{ mb: 1 }}>
          {t('account.contract.cancel')}
          </Button>
        </Box>
      );
    case HedgingStatus.Waiting:
      return (
        <Box className={classes.actions}>
          <Button variant="contained" fullWidth disabled>
          {t('account.contract.waitingForQuotation')} <CountdownTimer deadline={hedge.quotation_deadline} />
          </Button>
        </Box>
      );
    case HedgingStatus.Quotation:
      return (
        <Box className={classes.actions}>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <ActionTimer deadline={hedge.deadline} />
          </Box>
          <Button variant="contained" fullWidth onClick={onConfirm} sx={{ mb: 1 }}>
            {t('account.contract.CONFIRM')}
          </Button>
          <Button variant="outlined" color="error" fullWidth onClick={onRejectQuotation}>
            {t('account.contract.REJECT')}
          </Button>
        </Box>
      );

    case HedgingStatus.WaitingTP:
    case HedgingStatus.WaitingOLME:
    case HedgingStatus.AtWorkOLME:
    case HedgingStatus.AtWork:
      return (
        <Box className={classes.actions}>
          <Button variant="contained" fullWidth onClick={onCancel}>
            {t('account.contract.close')}
          </Button>
          <Button variant="outlined" color="error" fullWidth onClick={onRejectOrder}>
          {t('account.contract.rejectOrder')}
          </Button>
        </Box>
      );

    case HedgingStatus.Confirmed:
      return (
        <Box className={classes.actions}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'var(--other-50)',
              p: '12px',
              borderRadius: '8px',
            }}
          >
            <Typography>
            {t('account.contract.youConfirmed')}
            </Typography>
          </Box>
          <Button variant="contained" fullWidth onClick={onCancel}>
            {t('account.contract.DONE')}
          </Button>
        </Box>
      );
    case HedgingStatus.Filled:
    case HedgingStatus.FilledTP:
    case HedgingStatus.FilledOLME:
      return (
        <Box className={classes.actions}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'var(--other-50)',
              p: '12px',
              borderRadius: '8px',
            }}
          >
            <Typography>
            {t('account.contract.youCompleted')} {formatDate(hedge.filled_time)} at{' '}
              {formatTime(hedge.filled_time)}
            </Typography>
          </Box>
          <Button variant="contained" fullWidth onClick={onCancel}>
            {t('account.contract.done')}
          </Button>
        </Box>
      );
    default:
      return (
        <Box className={classes.actions}>
          <Button variant="contained" onClick={onCancel}>
            {t('account.contract.close')}
          </Button>
        </Box>
      );
  }
};
