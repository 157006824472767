import { TableContainer } from '@mui/material/';
import { useContractsTable } from './hooks/useContractsTable';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { ScrapexColorEnum } from '@common/colors';
import { TableHeader } from 'src/account/common';
import { useTranslation } from 'react-i18next';

export const ContractsTable = () => {
  const { columns, rows, handleOpenContractShipments } = useContractsTable();
  const { t } = useTranslation();

  return (
    <>
      <TableContainer sx={{ background: ScrapexColorEnum.WHITE_COLOR, maxHeight: '100vh' }}>
        <div style={{ width: '1540px', overflowX: 'auto', height: '85vh' }}>
          <DataGridPremium
            sx={{
              border: 'none',
              '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                outline: 'none',
              },

              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
              },
              '& .MuiDataGrid-columnHeaders': {
                color: '#8592A8',
                background: '#F9FAFB',
                border: 'none',
              },
            }}
            columnHeaderHeight={40}
            initialState={{
              pinnedColumns: {
                left: [
                  'deal_status',
                  'customer_role',
                  'contract_number',
                  'counterparty',
                  'contract_date',
                ],
              },
              sorting: {
                sortModel: [{ field: 'contract_date', sort: 'desc' }],
              },
            }}
            sortingOrder={['desc', 'asc']}
            slots={{ toolbar: TableHeader }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                title: t('account.contract.contracts'),
              },
            }}
            rows={rows}
            columns={columns}
            // disableColumnFilter
            hideFooter
            onRowClick={(row) => handleOpenContractShipments(row.id as string)}
            disableColumnMenu
            // disableRowSelectionOnClick
          />
        </div>
      </TableContainer>
    </>
  );
};
