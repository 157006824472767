import { GridColDef } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { CompanyStatusIndicator } from '../../../status';
import { formatDate, renderDateOrNone } from '@app/utils';
import { dateSortComparator } from '@app/utils/sortComparators';

export const generateCompaniesColumns = () => {
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'company_status',
      headerName: t('accountManager.table.status'),
      renderCell: (params) => <CompanyStatusIndicator status={params.value} />,
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'name',
      headerName: t('accountManager.table.companyName'),
      minWidth: 200,
      flex: 3,
    },
    {
      field: 'email',
      headerName: t('accountManager.table.email'),
      minWidth: 200,
      flex: 3,
    },
    {
      field: 'invitation_date',
      headerName: t('accountManager.table.invitationDate'),
      valueGetter: (params) => {
        return formatDate(params.value);
      },
      renderCell: (params) => renderDateOrNone(t, params.value),
      sortComparator: dateSortComparator,
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'registration_date',
      headerName: t('accountManager.table.registrationDate'),
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => {
        return formatDate(params.row.invitation_date);
      },
      renderCell: (params) => renderDateOrNone(t, params.row.invitation_date),
      sortComparator: dateSortComparator,
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'number_of_contracts',
      headerName:  t('accountManager.table.numContracts'),
      align: 'right',
      headerAlign: 'right',
      minWidth: 170,
      flex: 1.5,
    },
  ];

  return { columns };
};
