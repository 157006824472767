import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend) // Загружаем переводы из файлов
  .use(LanguageDetector) // Определяем язык пользователя
  .use(initReactI18next) // Подключаем к React
  .init({
    fallbackLng: 'en', // Язык по умолчанию
    debug: true, // Включаем debug-логи
    interpolation: {
      escapeValue: false, // Отключаем экранирование HTML
    },
  });

export default i18n;
