import { useTranslation } from 'react-i18next';
import classes from './styles.module.scss';
import mission from './assets/mission.png';
import principles from './assets/principles.png';

export const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <section id="about">
      <main className={`${classes.about} `}>
        <div className={classes.wrapper}>
          <img src={mission} width={400} />
          <div className={classes.textWrapper}>
            <h3>{t('landing.mission.ourMission.label')}</h3>
            <p>{t('landing.mission.ourMission.description')}</p>
          </div>
        </div>
        <div className={classes.wrapper}>
          <div className={classes.textWrapper}>
            <h3>{t('landing.mission.ourCorePrinciples.label')}</h3>
            <p>
              <p>
                <strong>{t('landing.mission.ourCorePrinciples.transparency')}</strong> <br />
                {t('landing.mission.ourCorePrinciples.transparencyDesc')}
              </p>{' '}
              <p>
                <strong>{t('landing.mission.ourCorePrinciples.trustAndIntegrity')}</strong> <br />
                {t('landing.mission.ourCorePrinciples.trustAndIntegrityDesc')}
              </p>{' '}
              <p>
                <strong>{t('landing.mission.ourCorePrinciples.innovate')}</strong> <br />
                {t('landing.mission.ourCorePrinciples.innovateDesc')}
              </p>{' '}
              <p>
                <strong>{t('landing.mission.ourCorePrinciples.compliance')}</strong> <br />
                {t('landing.mission.ourCorePrinciples.complianceDesc')}
              </p>{' '}
              <p></p>
            </p>
          </div>
          <img src={principles} width={400} />
        </div>
      </main>
    </section>
  );
};
