import { Link } from 'react-router-dom';
import classes from './styles.module.scss';

export const Header = () => {
  

  return (
    <header className={classes.header}>
      <div className={classes.header__content}>
        <div className={`${classes.navigation__content}`}>
          <div className={classes.header__logo}>
            <a href="#scrapex">
              <h2>
                Scrap<span>EX</span>
              </h2>
            </a>
          </div>
        </div>
        <nav className={`${classes.navigation}`}>
          <ul>
            <li>
              <a href="#why">Why ScrapEX</a>
            </li>
            <li>
              <a href="#services">Services</a>
            </li>
            <li>
              <a href="#experience">Our Experience</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#faq">FAQ</a>
            </li>
            <li>
              <a href="#contact">Lets Talk</a>
            </li>
          </ul>
        </nav>
        <Link to="/login">
          <button>Log in</button>
        </Link>
      </div>
    </header>
  );
};
