import { useTranslation } from 'react-i18next';
import { List } from '@mui/material';
import { DataListItem } from '@common/components';
import { renderValueOrNone } from '@app/utils';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';

interface ACMContentProps {
  totalWeight?: number;
  shipmentsCount: number;
}

export const ACMContent = ({ shipmentsCount }: ACMContentProps) => {
  const { t } = useTranslation();
  const hedge = useSelector((state: RootState) => state.account.hedging.currentHedge);

  return (
    <List sx={{ px: '16px', pb: 0 }}>
      {hedge && (
        <>
          <DataListItem title={t('account.contract.currentLmeOfferUsd')}>
            {renderValueOrNone(t, hedge.quotation)}
          </DataListItem>
          <DataListItem title={t('account.contract.fixedLmeUsd')}>
            {renderValueOrNone(t, hedge.fixed_exchange)}
          </DataListItem>
          <DataListItem title={t('account.contract.finalUnitPriceUsd')}>
            {renderValueOrNone(t, hedge.final_product_price)}
          </DataListItem>
          <DataListItem title={t('account.contract.productMetal')}>
            {hedge.product}/{hedge.metal}
          </DataListItem>
          <DataListItem title={t('account.contract.formula')}>{hedge.formula}</DataListItem>
          <DataListItem title={t('account.contract.hedgingShipments')}>{shipmentsCount}</DataListItem>
        </>
      )}
    </List>
  );
};
