/* eslint-disable no-else-return */
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { createTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ReactNode, useEffect } from 'react';
import { ThemeProvider } from '@mui/system';
import { IRouteObject, ROUTES } from './app/routes/routes';
import type { AppDispatch, RootState } from './app/store';
import { startConnecting, stopConnecting } from '@app/socket/reducer/slice/slice';
import { LicenseInfo } from '@mui/x-license-pro';
import './i18n'; // Подключаем i18n

const App = () => {
  const theme = createTheme({
    palette: {
      secondary: {
        main: '#0c2452',
      },
      info: {
        main: '#FFA726',
      },
    },
    typography: {
      allVariants: {
        fontFamily: 'Onest',
      },
    },
  });

  const dispatch = useDispatch<AppDispatch>();
  const role = useSelector((state: RootState) => state.auth.auth.role);

  useEffect(() => {
    if (role) {
      dispatch(startConnecting());
    }

    return () => {
      dispatch(stopConnecting());
    };
  }, [dispatch, role]);

  LicenseInfo.setLicenseKey(
    '61f5f175d3a66b90898f529a4740834cTz04NTQ2OCxFPTE3NDA4NDIxNTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
  );

  const roleParser = (rotes: IRouteObject[]): ReactNode => {
    return rotes.map((route) => {
      if (!route.access) {
        return (
          <Route element={route.component} path={route.path} key={route.path} index={route.isIndex}>
            {route.children && roleParser(route.children)}
          </Route>
        );
      } else if (route.access === role) {
        return (
          <Route element={route.component} path={route.path} key={route.path} index={route.isIndex}>
            {route.children && roleParser(route.children)}
          </Route>
        );
      }
      return <></>;
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Routes>{roleParser(ROUTES)}</Routes>
    </ThemeProvider>
  );
};

export default App;
