import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { CountdownTimer } from '@common/components/display/timer';

interface ActionTimerProps {
  deadline: string;
}

export const ActionTimer: React.FC<ActionTimerProps> = ({ deadline }) => {
  const { t } = useTranslation();
  
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#FEF0EF',
        p: '12px',
        borderRadius: '8px',
      }}
    >
      <Typography color="#F44336">
      {t('account.contract.youHave')}{' '}
        <span style={{ fontWeight: 500 }}>
          <CountdownTimer deadline={deadline} />
        </span>{' '}
        {t('account.contract.toMakeAdecision')}
      </Typography>
    </Box>
  );
};
