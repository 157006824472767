import { HedgingStatus, IContractAdmin, IShipment } from '@app/types';
import { formatDate } from '@app/utils';
import { noFileId } from '@app/utils/isFileExists';
import { hedgeStatusMap,  getShipmentStatusLabel } from '@common/components';
import { TFunction } from 'i18next';
import * as XLSX from 'xlsx';

const excelHeaders = [
  // Contract section
  'Contract No.',
  'Contract Status',
  'Seller contract',
  'Buyer contract',
  'Product',
  'Formula',
  'Contract Weight, mt',
  'Shipments',
  'Completed Shipments',

  // Shipment section
  'Ordinal No.',
  'Shipment No.',
  'Status',
  'Claim',
  'ETA',
  'Final Weight',
  'Estimated',
  'BL/Telex/SWB',
  'Quality Certificate',
  'Packing List',
  'Insurance Certificate',
  'Survey Report',
  'Other',

  // Seller section
  'Seller',
  'Hedging number',
  'Hedging type',
  'Hedging status',
  'Limit Fixation Date',
  'Shipment Value, USD',
  'Invoices',
  'Other',

  // Buyer section
  'Buyer',
  'Hedging number',
  'Hedging type',
  'Hedging status',
  'Limit Fixation Date',
  'Shipment Value, USD',
  'Invoices',
  'Other',
];

function isValue(value?: any): string {
  return value ? 'Yes' : 'None';
}

export const handleExport = (t: TFunction, contract: IContractAdmin, shipments: IShipment[]) => {
  const combinedData = getCombinedData(t ,contract, shipments);

  const worksheet = XLSX.utils.json_to_sheet(combinedData);
  XLSX.utils.sheet_add_aoa(worksheet, [excelHeaders], { origin: 'A1' });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Shipments');
  XLSX.writeFile(workbook, 'shipments_export.xlsx');
};

export const getCombinedData = (t: TFunction, contract: IContractAdmin, shipments: IShipment[]) => {
  const mapHedgingStatus = (status: string): string => {
    const mappedStatus = hedgeStatusMap[status as HedgingStatus];
    return t(`account.contract.${mappedStatus}`);
  };

  return shipments.map((shipment) => ({
    //contract
    contractNumber: contract.seller_contract_number,
    contractStatus: contract.deal_status,
    sellerContract: contract.seller_contract === noFileId ? 'None' : 'Yes',
    buyerContract: contract.buyer_contract === noFileId ? 'None' : 'Yes',
    product: contract.product,
    formula: contract.formula,
    contractWeight: contract.product_weight,
    shipments: contract.total_shipments,
    completedShipments: contract.completed_shipments,

    //shipment
    ordinalNumber: shipment.ordinal_number,
    shipmentNumber: shipment.shipment_number,
    status: getShipmentStatusLabel(t, shipment.shipment_status),
    claim: shipment.claims,
    eta: shipment.eta ? formatDate(shipment.eta) : 'None',
    finalWeight: shipment.final_weight,
    estimatedWeight: shipment.estimated_weight,
    connaissement: isValue(shipment.connaissement),
    qualityCertificate: isValue(shipment.quality_certificate),
    packingList: isValue(shipment.packing_list),
    insuranceCertificate: isValue(shipment.insurance_certificate),
    surveyReport: isValue(shipment.survey_report),
    other: shipment?.other_files?.length || 0,

    //seller
    seller: shipment.seller_name,
    sellerHedgingNumber: shipment?.seller_hedge?.hedge_number,
    sellerHedgingType: shipment?.seller_hedge?.hedge_type,
    sellerHedgingStatus: mapHedgingStatus(shipment?.seller_hedge?.hedge_status),
    sellerLFD: shipment.seller_limit_fixation_date
      ? formatDate(shipment.seller_limit_fixation_date)
      : 'None',
    sellerShipmentValue: shipment.seller_shipment_value,
    sellerInvoices: shipment.seller_invoices.length,
    sellerOther: shipment?.seller_other_files?.length || 0,

    // buyer
    buyer: shipment.buyer_name,
    buyerHedgingNumber: shipment?.buyer_hedge?.hedge_number,
    buyerHedgingType: shipment?.buyer_hedge?.hedge_type,
    buyerHedgingStatus: mapHedgingStatus(shipment?.buyer_hedge?.hedge_status),
    buyerLFD: shipment.buyer_limit_fixation_date
      ? formatDate(shipment.buyer_limit_fixation_date)
      : 'None',
    buyerShipmentValue: shipment.buyer_shipment_value,
    buyerInvoices: shipment.buyer_invoices.length,
    buyerOther: shipment?.buyer_other_files?.length || 0,
  }));
};
