import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import { DownloadingOutlined } from '@mui/icons-material';
import { useGetShipmentFiles } from '@app/hooks/useGetShipmentFiles';
import { DialogTitleModal } from '@common/components';
import { FileRow } from '../fileRow';

interface FilesModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  content?: string;
  dealId: string;
  folder: string;
  fileIds: string[];
}

export const FilesModal: React.FC<FilesModalProps> = ({
  open,
  onClose,
  title,
  dealId,
  folder,
  fileIds,
}) => {
  const { t } = useTranslation();
  const { link } = useGetShipmentFiles(dealId, folder);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: 800,
          width: 800,
          height: 539,
        },
      }}
    >
      <DialogTitleModal title={title} onClose={onClose} />

      <DialogContent
        sx={{
          px: '24px',
        }}
      >
        {fileIds &&
          fileIds.map((item, index) => (
            <FileRow
              key={item}
              fileId={item}
              dealId={dealId}
              hasDownloadFile
              sx={{
                justifyContent: 'space-between',
                backgroundColor: index % 2 !== 0 ? '#FAFAFA' : '#fff',
              }}
            />
          ))}
      </DialogContent>
      <DialogActions sx={{ p: '24px', gap: 2 }}>
        <Button variant="outlined" startIcon={<DownloadingOutlined />} href={link}>
          {t('account.contract.downloadAll')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
