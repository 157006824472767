import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import classes from './styles.module.scss';
import { CompanyStatus } from '@app/types';

export const CompanyStatusIndicator = ({ status }: { status: CompanyStatus }) => {
  const { t } = useTranslation();
  
  const companyStatusMap: { [key in CompanyStatus]: string } = {
    active: t('accountManager.statuses.active'),
    frozen: t('accountManager.statuses.frozen'),
    invited: t('accountManager.statuses.invited'),
    none: t('accountManager.statuses.noStatus'),
  };

  return (
    <Box className={`${classes[status]} ${classes.status}`}>
      {companyStatusMap[status as CompanyStatus]}
    </Box>
  );
};
