import { useTranslation } from 'react-i18next';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

export const OperatingTimeInfo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ background: 'var(--other-50)', borderRadius: '4px' }}>
      <Box p="12px" display="flex" gap={1}>
        <InfoOutlined />
        <Typography>
          {t('account.contract.workingHoursFrom')} 8:00 to 16:00 (London Time Zone)
        </Typography>
      </Box>
    </Box>
  );
};
