import { useTranslation } from 'react-i18next';
import { Box, Typography, Button, Dialog } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import classes from './styles.module.scss';
import { ScrapexGrayTextRegular } from '@common/components';

interface ConfirmationDialogProps {
  open: boolean;
  hedgeNo: string;
  isACMTab: boolean;
  orderType: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmationDialog = ({
  open,
  hedgeNo,
  isACMTab,
  orderType,
  onCancel,
  onConfirm,
}: ConfirmationDialogProps) => {
  const { t } = useTranslation();
  
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
      PaperProps={{ sx: { borderRadius: '24px' } }}
    >
      <Box className={classes.container}>
        <Box className={classes.iconWrapper}>
          <InfoOutlined color="primary" sx={{ fontSize: 50 }} className={classes.icon} />
        </Box>

        <Box className={classes.content}>
          <Typography variant="h6">{t('account.contract.hedgeRequest')}</Typography>
          <ScrapexGrayTextRegular>
            {t('account.contract.hedgeNo')} <span>{hedgeNo}</span>
          </ScrapexGrayTextRegular>

          <ScrapexGrayTextRegular>
            {isACMTab ? `${t('account.contract.atTheCurrentMarket')} (ACM)` : `${t('account.contract.orders')} (3-month) ${orderType}`}
          </ScrapexGrayTextRegular>

          <Typography className={classes.message}>
            {isACMTab
              ? t('account.contract.byClickingTheRequestAQuote')
              : t('account.contract.afterClickingOnThePlaceAnOrder')}
          </Typography>
        </Box>

        <Box className={classes.actions}>
          <Button variant="contained" color="primary" fullWidth onClick={onConfirm}>
            {isACMTab ? `${t('account.contract.REQUEST_A_QUOTE')}` : `${t('account.contract.PLACE_AN_ORDER')}`}
          </Button>
          <Button variant="outlined" color="error" fullWidth onClick={onCancel} sx={{ mt: 1 }}>
            {t('account.contract.CANCEL')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
