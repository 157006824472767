import { useTranslation } from 'react-i18next';
import { RootState } from '@app/store';
import { DataListItem, ScrapexSidebarTextH1 } from '@common/components';
import { useSelector } from 'react-redux';
import { OperatingTimeInfo } from './OperatingTimeInfo';
import { HedgeType } from '../../../helpers';
import { HedgingStatus } from '@app/types';
import { formatDate, renderValueOrNone, renderValueOrNoneInDrawer } from '@app/utils';

export const HedgeRequestContent = () => {
  const hedge = useSelector((state: RootState) => state.account.hedging.currentHedge);

  const { t } = useTranslation();
  
  return (
    <>
      {hedge && (
        <>
          {hedge.hedge_type === HedgeType.ACM && (
            <>
              <ScrapexSidebarTextH1>{t('account.contract.atTheCurrentMarket')} (ACM)</ScrapexSidebarTextH1>
              <OperatingTimeInfo />
              <DataListItem title={t('account.contract.currentLmeOfferUsd')}>
                {renderValueOrNone(t, hedge.quotation)}
              </DataListItem>
              <DataListItem title={t('account.contract.fixedLmeUsdMt')}>
                {renderValueOrNone(t, hedge.fixed_exchange)}
              </DataListItem>
              <DataListItem title={t('account.contract.finalUnitPriceUsdMt')}>
                {renderValueOrNone(t, hedge.final_product_price)}
              </DataListItem>
            </>
          )}
          {hedge.hedge_type === HedgeType.TakeProfit && (
            <>
              <ScrapexSidebarTextH1>{t('account.contract.takeProfitOrder')}</ScrapexSidebarTextH1>
              <DataListItem title={t('account.contract.orderType')}>{t('account.contract.takeProfit')} (TP)</DataListItem>
              <DataListItem title={t('account.contract.takeProfitExchange')}>
                {renderValueOrNoneInDrawer(hedge.tp_exchange_level)}
              </DataListItem>
              <DataListItem title={t('account.contract.date')}>GTC</DataListItem>
              {hedge.hedge_status === HedgingStatus.FilledTP ? (
                <>
                  <DataListItem title={t('account.contract.fixedLmeUsd')}>
                    {renderValueOrNoneInDrawer(hedge.fixed_exchange)}
                  </DataListItem>
                  <DataListItem title={t('account.contract.finalUnitPriceUsd')}>
                    {renderValueOrNoneInDrawer(hedge.final_product_price)}
                  </DataListItem>
                </>
              ) : (
                <DataListItem title={t('account.contract.estimatedProduct')}>
                  {renderValueOrNoneInDrawer(hedge.tp_estimated_product_price)}
                </DataListItem>
              )}
            </>
          )}
          {hedge.hedge_type === HedgeType.OLME && (
            <>
              <ScrapexSidebarTextH1>{t('account.contract.order')}</ScrapexSidebarTextH1>
              <DataListItem title={t('account.contract.orderType')}>{t('account.contract.officialLme')} (OLME)</DataListItem>
              <DataListItem title={t('account.contract.date')}>{formatDate(hedge?.olme_date)}</DataListItem>
              {hedge.hedge_status === HedgingStatus.FilledOLME && (
                <>
                  <DataListItem title={t('account.contract.fixedLmeUsd')}>
                    {renderValueOrNoneInDrawer(hedge.fixed_exchange)}
                  </DataListItem>
                  <DataListItem title={t('account.contract.finalUnitPriceOlme')}>
                    {renderValueOrNoneInDrawer(hedge.final_product_price)}
                  </DataListItem>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
