import { useTranslation } from 'react-i18next';
import { IHedge } from '@app/types';
import { formatDate, formatTime, renderDateOrNoneInDrawer } from '@app/utils';
import {
  CollapsableDataListItem,
  CommentCard,
  DataListItem,
  HedgingStatusIndicator,
  ShipmentCard,
  TextWithSubtitle,
} from '@common/components';
import { List } from '@mui/material';

export const HedgeRequestDrawerInfo = ({ hedge }: { hedge: IHedge }) => {
  const { t } = useTranslation();

  return (
    <>
      <List sx={{ mb: '20px' }}>
        <DataListItem title="Status">
          <HedgingStatusIndicator status={hedge.hedge_status} />
        </DataListItem>
        <DataListItem title={t('hedge.company')}>{hedge.company_name}</DataListItem>
        <DataListItem title={t('hedge.contract')}>{hedge.contract_number}</DataListItem>
        <DataListItem title={t('hedge.requestDate')}>
          <TextWithSubtitle
            title={formatDate(hedge.request_time)}
            subtitle={formatTime(hedge.request_time)}
          />
        </DataListItem>
        <DataListItem title={t('hedge.productMetal')}>
          {hedge.product}/{hedge.metal}
        </DataListItem>
        <DataListItem title={t('hedge.offeredFormula')}>{hedge.formula}</DataListItem>

        {/* {hedge.market.prices.ask}/{hedge.marketprices.bid} {hedge.market.prices.metal} */}
        <CollapsableDataListItem title={t('hedge.hedgingShipments')} input={hedge.hedge_shipments.length}>
          {hedge.hedge_shipments.map((shipment) => (
            <ShipmentCard shipment={shipment} key={shipment.id} />
          ))}
        </CollapsableDataListItem>
        <DataListItem title={t('hedge.hedgingWeight')}> {hedge.weight.toFixed(3)}</DataListItem>
        <DataListItem title={t('hedge.limitFixationDate')}>
          {renderDateOrNoneInDrawer(t, hedge.limit_fixation_date)}
        </DataListItem>
        {hedge.message && (
          <CollapsableDataListItem title={t('hedge.comment')}>
            <CommentCard comment={hedge.message} />
          </CollapsableDataListItem>
        )}
      </List>
    </>
  );
};
