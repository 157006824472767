import { Box } from '@mui/material';
import { ScrapexTitleH3 } from '@common/components';
import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';

interface TableHeaderProps {
  title: string;
}

export const TableHeader: React.FC<TableHeaderProps> = ({ title }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '64px',
        p: '20px',
      }}
    >
      <ScrapexTitleH3>{title}</ScrapexTitleH3>
      <GridToolbarContainer>
        <Box sx={{ flexGrow: 1, color: 'yellow' }}>
          <GridToolbarQuickFilter
            InputProps={{
              disableUnderline: true,
              placeholder: t('account.contract.search'),
              sx: {
                borderRadius: '10px',
                background: '#F9FAFB',
                width: '400px',
                height: '34px',
                padding: '9px 8px',
              },
            }}
          />
        </Box>
      </GridToolbarContainer>
    </Box>
  );
};
